import { FC, useEffect, useMemo, useState } from 'react';

import { useRecoilValue } from 'recoil';

import { useActiveFeventListForWorkerHook } from '@shared/api/fevent/fevent.hook';
import { FeventList } from '@shared/api/fevent/fevent.interface';
import { activeFeventListForWorkerStateSelector } from '@shared/api/fevent/fevent.selector';
import { searchTextAtomFamily } from '@shared/containers/SearchBar/state/searchBar.atom';
import { useAuth } from '@shared/state/hooks/useAuth';
import FuzzySearch from 'fuzzy-search';

import SearchBar from '../../../@shared/containers/SearchBar/SearchBar';
import { BugoCard } from '../components/BugoCard';
import { useTitleHook } from '../hooks/useTitleHook';

/**
 * 부고 관리 (리스트) 페이지
 * @returns page, container
 */

const SEARCH_TEXT_ID = 'bugoManagePage';

//*지도사용 부고관리 페이지
const FeventManagePage: FC = function FeventManagePage() {
  useTitleHook('부고 관리', undefined, undefined, true, '/');

  const { isBugoAgencyWorker } = useAuth();

  //* 지도사용
  useActiveFeventListForWorkerHook();

  const feventListForWorkerState = useRecoilValue(activeFeventListForWorkerStateSelector);

  const searchText = useRecoilValue(searchTextAtomFamily(SEARCH_TEXT_ID));
  const [searchedList, setSearchedList] = useState<FeventList>([]);

  const feventList = useMemo(() => {
    if (feventListForWorkerState.status === 'success' && feventListForWorkerState.data) {
      return feventListForWorkerState.data;
    } else return [];
  }, [feventListForWorkerState.data, feventListForWorkerState.status]);

  useEffect(() => {
    // text가 빈 문자열이라면 feventList 전체 리턴.
    if (searchText.length === 0) return setSearchedList(feventList);

    const searcher = new FuzzySearch(
      feventList,
      [
        'deceasedInfo.name',
        'memberOrderList.fullName',
        'funeralHomeInfo.name',
        'roomInfo.name',
      ],
      {
        caseSensitive: true,
      },
    );
    const result = searcher.search(searchText);
    setSearchedList(result);
  }, [searchText, isBugoAgencyWorker, feventList]);

  return (
    <div className="px-4 pb-10">
      {/* Search Bar */}
      <div className="px-2 py-4">
        <SearchBar
          searchTextAtomId={SEARCH_TEXT_ID}
          placeholder="성함/장례식장/호실로 검색하세요."
        />
      </div>
      {/* BugoCards */}
      <div className="space-y-3">
        {searchedList.map((fevent) => (
          <BugoCard fevent={fevent} key={'bugocard' + fevent._id} />
        ))}
      </div>
    </div>
  );
};

export default FeventManagePage;
