import { FC, useMemo } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import CourtesyTopBanner from '@service/bugo/components/CourtesyTopBanner';
import { BugoData } from '@shared/api/bugo/bugo.interface';
import {
  courtesyAssetListByMemberSelector,
  courtesyContentListByMemberSelector,
} from '@shared/api/courtesyAsset/courtesyAsset.selector';
import { Button } from '@shared/components/Button';
import { WebCourtesyShareModal } from '@shared/containers/WebCourtesyShareModal/WebCourtesyShareModal';
import { simpleOpenAtomFamily } from '@shared/state/atom';

import { MemberCourtesyAssetSelector } from './MemberCourtesyAssetSelecter';
import { MemberCourtesyContentSelector } from './MemberCourtesyContentSelector';
import { MemberCourtesyPreviewModal } from './MemberCourtesyPreviewModal';
import { courtesyContentStringAtom } from './courtesy.atom';

interface Props {
  bugo: BugoData;
}

//* 상주용 답례글 생성기
export const MemberCourtesySender: FC<Props> = function MemberCourtesySender({
  bugo,
}: Props) {
  //* 필요한 state
  const courtesyContentString = useRecoilValue(courtesyContentStringAtom);

  const courtesyAssetListState = useRecoilValue(courtesyAssetListByMemberSelector);
  const courtesyContentListState = useRecoilValue(courtesyContentListByMemberSelector);

  const memberCourtesyAssetSelector = useMemo(() => {
    if (courtesyAssetListState.status === 'success' && courtesyAssetListState.data) {
      return (
        <MemberCourtesyAssetSelector
          bugo={bugo}
          courtesyAssetList={courtesyAssetListState.data}
        />
      );
    }
  }, [bugo, courtesyAssetListState.data, courtesyAssetListState.status]);

  const memberCourtesyCotentSelector = useMemo(() => {
    if (courtesyContentListState.status === 'success' && courtesyContentListState.data) {
      return (
        <MemberCourtesyContentSelector
          courtesyContentList={courtesyContentListState.data}
          bugo={bugo}
        />
      );
    }
  }, [bugo, courtesyContentListState.data, courtesyContentListState.status]);

  const previewOpenId = `courtesy-preview-${bugo._id}`;
  const setPreviewOpen = useSetRecoilState(simpleOpenAtomFamily(previewOpenId));
  const shareOpenId = `courtesyShare-${bugo._id}`;
  const setShareOpen = useSetRecoilState(simpleOpenAtomFamily(shareOpenId));
  return (
    <div className="space-y-4">
      <CourtesyTopBanner fevent={bugo.fevent}></CourtesyTopBanner>
      {memberCourtesyAssetSelector}
      {memberCourtesyCotentSelector}

      <MemberCourtesyPreviewModal openId={previewOpenId} />
      <>
        <div className="mt-4 grid grid-cols-2 font-bold text-white">
          <Button
            text="답례인사(이미지) 다운로드"
            className="button-rectangle theme-bg-main"
            onClick={() => {
              setPreviewOpen(true);
            }}
          />
          <Button
            text="답례글 공유"
            className="button-rectangle bg-gray-800"
            onClick={() => {
              setShareOpen(true);
            }}
          />
        </div>
        <WebCourtesyShareModal
          openId={shareOpenId}
          content={courtesyContentString ?? ''}
          title={''}
          kakaoContent={{
            courtesyContent: courtesyContentString ?? '',
          }}
        />
      </>
    </div>
  );
};
