import React from 'react';

import { BugoSignupForm } from '@service/bugo/components/BugoSignupForm';
import { useTitleHook } from '@service/bugo/hooks/useTitleHook';
import topLogo from 'src/assets/logo/topLogo.png';

//! pseudocode
/**
 * @components
 * @shared
 *! - Input(TextField)
 *! - Button
 * @structure
 *! - Logo
 *! - Title
 *! - SubmitForm
 *! |- InputSet
 *! ||- Id
 *! ||- Password
 *! |- ButtonSet
 *! ||- PersistCheckBox
 *! ||- SubmitButton
 *! ||- FindIdButton
 *! ||- SignupButton
 */

const BugoSignupPage: React.FC = () => {
  // //TODO: text용도, 나중에 지워야 함
  // const [theme, setTheme] = useRecoilState(customThemeAtom);

  // const themeToggle = useCallback(() => {
  //   switch (theme) {
  //     case UserCustomTheme.Default:
  //       setTheme(UserCustomTheme.Preed);
  //       break;
  //     case UserCustomTheme.Preed:
  //       setTheme(UserCustomTheme.Dark);
  //       break;
  //     case UserCustomTheme.Dark:
  //       setTheme(UserCustomTheme.Default);
  //       break;
  //     default:
  //       setTheme(UserCustomTheme.Dark);
  //   }
  // }, [theme, setTheme]);
  // //TODO: 여기까지
  useTitleHook('회원가입');

  return (
    <React.Fragment>
      {/* Title */}
      <div className="mt-8 pl-4 pr-4">
        <div className="px-3 pt-4 pb-4 shadow-smd theme-bg-JM05">
          {/* Logo */}
          <div className="grid place-items-center">
            <img src={topLogo} className="h-[60px]"></img>
          </div>
          {/* SubmitForm */}
          <BugoSignupForm />
        </div>
      </div>
    </React.Fragment>
  );
};

export { BugoSignupPage };
