import { FC } from 'react';

import { TextField } from '@shared/components/TextField';
import { UseFormRegister } from 'react-hook-form';

interface IProps {
  register: UseFormRegister<any>;
  names: {
    receiverName: string;
    receiverAddress: string;
  };
}

const ReceiverInfoInputs: FC<IProps> = ({
  register,
  names: { receiverName, receiverAddress },
}: IProps) => {
  return (
    <div className="font-bold">
      <h4>수령인 정보</h4>
      <div className="grid grid-cols-1 grid-rows-2 gap-y-3 pt-3">
        <TextField
          className="w-full"
          placeholder="수령인 성함"
          {...register(receiverName, { required: true })}
        />
        <TextField
          type="text"
          className="w-full"
          placeholder="수령지"
          {...register(receiverAddress, { required: true })}
        />
      </div>
    </div>
  );
};

export default ReceiverInfoInputs;
