import { ShopOrderForSeller } from './shopOrder.interface';

export const orderContentString = (shopOrder: ShopOrderForSeller) => {
  return (
    `주소: ${shopOrder.deliveryDetail.receiverAddress}\n` +
    `문구(보내는분): ${shopOrder.bugoDetail.senderPhrase}\n` +
    `조의문구: ${shopOrder.bugoDetail.condolencePhrase}\n` +
    `받으시는분: ${shopOrder.deliveryDetail.receiverName} 상주님\n` +
    `도매가: ${shopOrder.orderDetail.shopItemEmbed.priceWhole.toLocaleString()}원`
  );
};
