import React, { ButtonHTMLAttributes } from 'react';

import { LoginForm } from '@shared/components/LoginForm';
import topLogo from 'src/assets/logo/topLogo.png';

//! pseudocode
/**
 * @components
 * @shared
 *! - Input(TextField)
 *! - Button
 * @structure
 *! - Logo
 *! - Title
 *! - SubmitForm
 *! |- InputSet
 *! ||- Id
 *! ||- Password
 *! |- ButtonSet
 *! ||- PersistCheckBox
 *! ||- SubmitButton
 *! ||- FindIdButton
 *! ||- SignupButton
 */
export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string;
  to?: string;
}

const BugoLoginPage: React.FC = () => {
  return (
    <div className="pt-10">
      {/* Logo */}
      <div className="grid place-items-center">
        <img src={topLogo} className="h-[60px]"></img>
      </div>
      {/* Title */}
      <div className="flex w-full justify-center">
        <div className="mx-6 mt-8 w-full max-w-md">
          <div className="py-10 px-3 pb-8 shadow-smd theme-bg-JM05">
            <div>
              {/* SubmitForm */}
              <LoginForm redirectUrl="/" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { BugoLoginPage };

{
  /* <div className="inline-flex w-full items-center justify-center">
<hr className="my-6 h-px w-full border-0 bg-[#D1D5DB]" />
<span className="absolute left-1/2 -translate-x-1/2 px-3 text-12 theme-text-8 theme-bg-JM05">
  회원이 아니세요?
</span>
</div>
<BugoBasicButton
onClick={() => {
  navigate('/signup');
}}
className="rounded-lg"
colorTheme="default"
text="회원가입"
/>
<div className="inline-flex w-full items-center justify-center">
<hr className="my-6 h-px w-full border-0 bg-[#D1D5DB]" />
<span className="absolute left-1/2 -translate-x-1/2 px-3 text-12 theme-text-8 theme-bg-JM05">
  간편로그인
</span>
</div>
<BugoBasicButton
onClick={() =>
  loginWithRedirect({ redirectUri: `${window.location.origin}` })
}
className="relative rounded-lg"
colorTheme="default"
>
<GoogleLogo className="absolute left-3.5 h-5 w-5" />
<KaKaoLogo className="absolute left-10 h-5 w-5 text-kakao-brown" />
{window.innerWidth > 320 ? (
  <span>간편로그인으로 시작하기</span>
) : (
  <span>간편로그인</span>
)}
</BugoBasicButton> */
}
