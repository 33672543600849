import { api_ } from '@shared/plugIn/axios';
import { LowerRole } from '@shared/types';

import {
  bugoAgencyWorkerUpdate,
  GuestInfo,
  User,
  UserCreate,
  UserUpdate,
} from './user.interface';

export const nonAdminUpdateUser = async (userUpdate: bugoAgencyWorkerUpdate) => {
  const { data: user } = await api_.patch(`auth/me/detail`, userUpdate);
  return user;
};

export const createOrGetStoreGuest = async (guestInfo: GuestInfo, role: LowerRole) => {
  const { data: user } = await api_.post<User>(
    `${role}/user/create-or-get/store-guest`,
    guestInfo,
  );
  return user;
};

export const adminCreateUser = async (userCreate: UserCreate) => {
  const { data: user } = await api_.post<User>('admin/user', userCreate);
  return user;
};

export const adminUpdateUser = async (userUpate: UserUpdate) => {
  const { data: user } = await api_.patch<User>(`admin/user/${userUpate._id}`, userUpate);
  return user;
};
