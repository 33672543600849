import { FC } from 'react';

import { useSetRecoilState } from 'recoil';

import { FeventByBugoBrand } from '@shared/api/fevent/fevent.interface';
import { Button } from '@shared/components/Button';
import { Icon } from '@shared/components/icons';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { useId } from 'react-id-generator';

import BugoDetailModal from './BugoDetailModal';

interface IProps {
  fevent: FeventByBugoBrand | null;
}

const BugoDetailModalOpener: FC<IProps> = ({ fevent }: IProps) => {
  const [id] = useId(1, 'bugoDetailModalOpener');
  const openId = `modal-${id}`;
  const setOpen = useSetRecoilState(simpleOpenAtomFamily(openId));
  const { ChevronRight } = Icon();

  const onClick = () => {
    if (fevent) setOpen(true);
  };

  return (
    <>
      <Button className="rounded-sm p-0.5 shadow-sm" onClick={onClick}>
        <ChevronRight className="wh-6" />
      </Button>
      {fevent && <BugoDetailModal openId={openId} fevent={fevent} />}
    </>
  );
};

export default BugoDetailModalOpener;
