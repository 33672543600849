import { FC, useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import { useShopOrderWaitingListForSellerByShopHook } from '@shared/api/shopOrder/shopOrder.hook';
import { shopOrderWaitingListForSellerByShopStateSelectorFamily } from '@shared/api/shopOrder/shopOrder.selector';
import { useParams } from 'react-router-dom';

import { WaitingShopOrderCard } from '../components/WaitingShopOrderCard';
import { useSellerTopNavHook } from '../hooks/useSellerTopNavHook';

export const SellerWaitingListPage: FC = function SellerWaitingListPage() {
  const { shopId } = useParams();

  useSellerTopNavHook('입금대기내역');

  useShopOrderWaitingListForSellerByShopHook(`${shopId}`);

  const shopOrderWaitingListState = useRecoilValue(
    shopOrderWaitingListForSellerByShopStateSelectorFamily(`${shopId}`),
  );

  const waitingOrderGrid = useMemo(() => {
    if (
      shopOrderWaitingListState.status === 'success' &&
      shopOrderWaitingListState.data
    ) {
      const shopOrderList = shopOrderWaitingListState.data;
      return (
        <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          {shopOrderList.map((shopOrder) => {
            return <WaitingShopOrderCard shopOrder={shopOrder} key={shopOrder._id} />;
          })}
        </div>
      );
    }
  }, [shopOrderWaitingListState.data, shopOrderWaitingListState.status]);

  return (
    <div className="mx-auto max-w-6xl px-4 pb-8 sm:px-6 lg:px-8">
      <div className="mt-8 space-y-4">{waitingOrderGrid}</div>
    </div>
  );
};
