import { selector, selectorFamily } from 'recoil';

import {
  userDataAtom,
  userStatusAtom,
  workerUserDataAtomFamily,
  workerUserListByBugoBrandDataAtomFamily,
  workerUserListByBugoBrandStatusAtomFamily,
  workerUserStatusAtomFamily,
} from './user.atom';
import { UserState, WorkerUserListState, WorkerUserState } from './user.interface';

export const userStateSelector = selector<UserState>({
  key: 'userStateSelector',
  get: ({ get }) => {
    return {
      status: get(userStatusAtom),
      data: get(userDataAtom),
    };
  },
});

export const workerUserByBugoBrandStateSelector = selectorFamily<
  WorkerUserListState,
  string
>({
  key: 'workerUserByBugoBrandStateSelector',
  get:
    (id: string) =>
    ({ get }) => {
      return {
        status: get(workerUserListByBugoBrandStatusAtomFamily(id)),
        data: get(workerUserListByBugoBrandDataAtomFamily(id)),
      };
    },
});

export const workerUserStateSelector = selectorFamily<WorkerUserState, string>({
  key: 'workerUserStateSelector',
  get:
    (id: string) =>
    ({ get }) => {
      return {
        status: get(workerUserStatusAtomFamily(id)),
        data: get(workerUserDataAtomFamily(id)),
      };
    },
});
