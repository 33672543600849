import { FC } from 'react';

import { Coord } from '@shared/interfaces/map.interface';
import kakaoNaviLogo from 'src/assets/logo/kakao-navi-logo.png';
import naverNaviLogo from 'src/assets/logo/naver-navi-logo.png';
import tmapLogo from 'src/assets/logo/tmap-logo.png';

import Modal from './Modal';

interface Props {
  openId: string;
  coords: Coord;
  destinationName: string;
}

const origin = window.location.origin;

const NavigatorsModal: FC<Props> = ({ openId, coords, destinationName }: Props) => {
  const { x, y } = coords;

  return (
    <Modal id={openId} openId={openId} className="w-full" position="center">
      <div className="flex">
        <a
          // https://guide.ncloud-docs.com/docs/naveropenapiv3-maps-url-scheme-url-scheme
          href={`nmap://navigation?dlat=${y}&dlng=${x}&dname=${destinationName}&appname=${origin}`}
          target="_blank"
          rel="noopener noreferrer"
          className="flex flex-1 flex-col items-center space-y-2"
        >
          <img src={naverNaviLogo} className="w-14" />
          <h4 className="text-sm font-bold">네이버</h4>
        </a>
        <a
          // https://apis.map.kakao.com/web/guide/#mapurl
          href={`https://map.kakao.com/link/to/${destinationName},${y},${x}`}
          target="_blank"
          rel="noopener noreferrer"
          className="flex flex-1 flex-col items-center space-y-2"
        >
          <img src={kakaoNaviLogo} className="w-14" />
          <h4 className="text-sm font-bold">카카오</h4>
        </a>
        <a
          // https://okky.kr/articles/712480
          href={`tmap://route?goalname=${destinationName}&goaly=${y}&goalx=${x}`}
          target="_blank"
          rel="noopener noreferrer"
          className="flex flex-1 flex-col items-center space-y-2"
        >
          <img src={tmapLogo} className="w-14" />
          <h4 className="text-sm font-bold">티맵</h4>
        </a>
      </div>
    </Modal>
  );
};

export default NavigatorsModal;
