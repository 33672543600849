import { FuneralHomeInfoEmbed } from '@shared/api/funeralHomeInfoRequest/funeralHomeInfoRequest.interface';
import { FeventDate, Gender } from '@shared/types';

//* 고인정보입력을 위한 parameters
export enum DeceasedInfoFormInputsEnum {
  RegisterNumber = 'registerNumber',
  Name = 'name',
  Age = 'age',
  Sex = 'sex',
  BugoTerm = 'bugoTerm',
  EnteranceDate = 'enteranceDate',
  DeathDate = 'deathDate',
  CoffinInDate = 'coffinInDate',
  CoffinOutDate = 'coffinOutDate',
  Cemetery = 'cemetery',
  FuneralHomeInfo = 'funeralHomeInfo',
  FuneralHomeInfoName = 'funeralHomeInfoName',
  FuneralHomeInfoEmbed = 'funeralHomeInfoEmbed',
  RoomInfo = 'roomInfo',
  RoomInfoName = 'roomInfoName',
  RoomInfoEmbedName = 'roomInfoEmbedName',
  MournerText = 'mournerText',
  DpImage = 'dpImage',
}

export interface DeceasedInfoIFormInputs {
  [DeceasedInfoFormInputsEnum.Name]: string;
  [DeceasedInfoFormInputsEnum.Age]: number;
  [DeceasedInfoFormInputsEnum.Sex]: Gender;
  [DeceasedInfoFormInputsEnum.DeathDate]: FeventDate;
  [DeceasedInfoFormInputsEnum.EnteranceDate]: FeventDate;
  [DeceasedInfoFormInputsEnum.CoffinInDate]: FeventDate;
  [DeceasedInfoFormInputsEnum.CoffinOutDate]: FeventDate;
  [DeceasedInfoFormInputsEnum.Cemetery]: string;
  [DeceasedInfoFormInputsEnum.BugoTerm]: string;
  [DeceasedInfoFormInputsEnum.FuneralHomeInfo]: string;
  [DeceasedInfoFormInputsEnum.FuneralHomeInfoName]: string;
  [DeceasedInfoFormInputsEnum.FuneralHomeInfoEmbed]?: FuneralHomeInfoEmbed;
  [DeceasedInfoFormInputsEnum.RoomInfo]: string;
  [DeceasedInfoFormInputsEnum.RoomInfoName]: string;
  [DeceasedInfoFormInputsEnum.RoomInfoEmbedName]?: string;
  [DeceasedInfoFormInputsEnum.MournerText]: string;
  [DeceasedInfoFormInputsEnum.RegisterNumber]?: string;
  [DeceasedInfoFormInputsEnum.DpImage]?: string;
}
