//* teamName을 얻는 함수

import { User, WorkerUser } from '@shared/api/user/user.interface';

export const getAgencyWorkerTeamName = (user: User | WorkerUser) => {
  if (user.bugoAgencyWorkerDetail?.teamName || user.bugoAgencyWorkerDetail?.teamType) {
    if (user.bugoAgencyWorkerDetail.teamName === user.bugoAgencyWorkerDetail.teamType) {
      return user.bugoAgencyWorkerDetail.teamType;
    } else {
      return (
        user.bugoAgencyWorkerDetail.teamType +
        '(' +
        user.bugoAgencyWorkerDetail.teamName +
        ')'
      );
    }
  } else {
    return '';
  }
};
