import { FC } from 'react';

import { ShopOrderForAgencyAdmin } from '@shared/api/shopOrder/shopOrder.interface';

interface Props {
  shopOrderList: ShopOrderForAgencyAdmin[];
}

const AgencyAdminShopOrderStatusBar: FC<Props> = ({ shopOrderList }: Props) => {
  const totalRebatePrice = shopOrderList.reduce((accu, prev) => {
    return accu + prev.orderDetail.shopItemEmbed.priceRebate;
  }, 0);

  return (
    <div className="flex items-center py-2 text-xs font-bold theme-bg-1">
      {/* 화환 */}
      <div className="flex-1 border-r pb-1">
        <div className="flex flex-col items-center space-y-1">
          <h6 className="theme-text-main">화환</h6>
          <p className="w-full text-center">{shopOrderList.length.toLocaleString()}건</p>
        </div>
      </div>
      {/* 이익금 */}
      <div className="flex-1 pb-1">
        <div className="flex flex-col items-center space-y-1">
          <h6 className="theme-text-main">이익금</h6>
          <p className="w-full text-center">{totalRebatePrice.toLocaleString()}원</p>
        </div>
      </div>
    </div>
  );
};

export default AgencyAdminShopOrderStatusBar;
