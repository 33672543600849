//* 꽃집에서 부고를 조회하기 위한 페이지(전화주문용)
import { FC, useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import { useFeventByShopHook } from '@shared/api/fevent/fevent.hook';
import { feventByShopStateSelectorFamily } from '@shared/api/fevent/fevent.selector';
import { Loading } from '@shared/components/Loading';
import { useParams } from 'react-router-dom';

import { SellerFeventSearchTable } from '../containers/SellerFeventSearchTable/SellerFeventSearchTable';
import { useSellerTopNavHook } from '../hooks/useSellerTopNavHook';

const SellerBugoSearchPage: FC = function SellerBugoSearchPage() {
  const { shopId } = useParams();

  useSellerTopNavHook('부고 조회');
  useFeventByShopHook(`${shopId}`);

  const feventState = useRecoilValue(feventByShopStateSelectorFamily(`${shopId}`));

  const feventSearchTable = useMemo(() => {
    if (feventState.status === 'success' && feventState.data) {
      const feventList = feventState.data;
      return <SellerFeventSearchTable feventList={feventList}></SellerFeventSearchTable>;
    } else {
      <div className="center-box">
        <Loading />
      </div>;
    }
  }, [feventState.data, feventState.status]);

  return (
    <div className="mx-auto w-full max-w-6xl px-4 pb-8 pt-8 sm:w-fit sm:px-6 lg:px-8">
      <div className="w-full space-y-4 font-medium">{feventSearchTable}</div>
    </div>
  );
};

export default SellerBugoSearchPage;
