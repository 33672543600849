//* 조문객이 보는 Router
import { FC } from 'react';

import { useRecoilValue } from 'recoil';

import BugoObituaryPage from '@service/bugo/page/BugoObituaryPage';
import BugoObituaryPreviewPage from '@service/bugo/page/BugoObituaryPreviewPage';
import { useBugoHook } from '@shared/api/bugo/bugo.hook';
import { bugoStateSelectorFamily } from '@shared/api/bugo/bugo.selector';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';

const BugoIdRouter: FC = () => {
  const { bugoId } = useParams();
  useBugoHook(bugoId ?? 'undefined', false);

  const bugoState = useRecoilValue(bugoStateSelectorFamily(`${bugoId}`));

  return (
    <div className={`${bugoState.data?.bugoBrand?.bugoAgency?.theme} max-w-inherit`}>
      <Routes>
        <Route path="" element={<BugoObituaryPage />} />
        <Route path="preview" element={<BugoObituaryPreviewPage />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </div>
  );
};

export default BugoIdRouter;
