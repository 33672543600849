import { FC } from 'react';

import OrderCancleRequestPage from '@service/store/page/OrderCancleRequestPage';
import OrderDeliveryPage from '@service/store/page/OrderDeliveryPage';
import OrderDetailPage from '@service/store/page/OrderDetailPage';
import OrderPaymentResultPage from '@service/store/page/OrderPaymentResultPage';
import { useShopOrderForStoreHook } from '@shared/api/shopOrder/shopOrder.hook';
import { useAuth } from '@shared/state/hooks/useAuth';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';

const OrderIdRouter: FC = () => {
  const { storeRole } = useAuth();
  const { orderId } = useParams();

  useShopOrderForStoreHook(orderId ?? 'undefined', storeRole(), Boolean(orderId));

  return (
    <Routes>
      <Route path="" element={<OrderDetailPage />} />
      <Route path="delivery" element={<OrderDeliveryPage />} />
      <Route path="payment" element={<OrderPaymentResultPage />} />
      <Route path="cancel" element={<OrderCancleRequestPage />} />
      <Route path="*" element={<Navigate to="/flower-store" />} />
    </Routes>
  );
};

export default OrderIdRouter;
