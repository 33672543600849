import { FC } from 'react';

import { StoreNavBar } from '@service/store/containers/StoreNavBar/StoreNavBar';
import { ContentTermsPage } from '@service/store/page/ContentTermsPage';
import StoreEnquiryPage from '@service/store/page/StoreEnquiryPage';
import StoreFAQPage from '@service/store/page/StoreFAQPage';
import { StoreLoginPage } from '@service/store/page/StoreLoginPage';
import StoreMainPage from '@service/store/page/StoreMainPage';
import { StoreSignupPage } from '@service/store/page/StoreSignupPage';
import { useAuth } from '@shared/state/hooks/useAuth';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import ItemIdRouter from './ItemIdRouter';
import StoreAuthRouter from './StoreAuthRouter';

export const StoreRouter: FC = function StoreRouter() {
  const { guestAuthenticated } = useAuth();
  const { pathname, search } = useLocation();

  if (
    pathname !== '/flower-store/login' &&
    pathname !== '/flower-store' &&
    pathname !== '/signup/init' &&
    !guestAuthenticated
  ) {
    sessionStorage.setItem('redirectUrlAfterStoreLogin', pathname + search);
  }

  const notUseNavBar = pathname.endsWith('login') || pathname.endsWith('error');

  return (
    <div className="max-w-inherit">
      <StoreNavBar />
      <div className={notUseNavBar ? '' : 'pt-14'}>
        <Routes>
          <Route path="" element={<StoreMainPage />}></Route>
          <Route path="item/:itemId/*" element={<ItemIdRouter />} />
          <Route path="faq" element={<StoreFAQPage />} />
          <Route path="enquiry" element={<StoreEnquiryPage />} />
          <Route path="terms" element={<ContentTermsPage />} />

          {/* logout required */}
          <Route
            path="login"
            element={
              !guestAuthenticated ? <StoreLoginPage /> : <Navigate to="/flower-store" />
            }
          ></Route>
          <Route
            path="signup"
            element={
              !guestAuthenticated ? <StoreSignupPage /> : <Navigate to="/flower-store" />
            }
          ></Route>

          {/* login required */}
          <Route path="*" element={<StoreAuthRouter />} />
        </Routes>
      </div>
    </div>
  );
};
