import { FC, useCallback, useEffect } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import {
  activeFeventListForWorkerHookUrl,
  feventHookUrl,
} from '@shared/api/fevent/fevent.hook';
import { FeventOne } from '@shared/api/fevent/fevent.interface';
import { Button } from '@shared/components/Button';
import { LoadingModal } from '@shared/components/LoadingModal';
import {
  notificateError,
  notificateSuccess,
} from '@shared/plugIn/ant-notification/ant-notifiaction';
import { defaultApiMutationOptions } from '@shared/plugIn/apiMutation';
import { notificationInstanceAtom } from '@shared/state/atom/notification.atom';
import { useAuth } from '@shared/state/hooks/useAuth';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import {
  MemberFormInputsEnum,
  MemberIFormInputs,
  vacantMemberInputForm,
} from '../../interfaces/memberAddEdit.interface';
import { MemberListEditor } from '../MemberListEditor/MemberListEditor';
import {
  cashAccountListByFeventAtom,
  feventIdInMemberListEditorAtom,
} from '../MemberListEditor/memberListEditor.atom';
import { mournerInfoFormSubmit } from './mournerInfoFormUtils';

interface Props {
  fevent: FeventOne;
  editMode?: boolean;
}

//* 초기값 세팅
const defaultMemberInputForm = (fevent: FeventOne) => {
  if (fevent.memberOrderList.length > 0) {
    const memberOrderList = fevent.memberOrderList;
    const memberInputForm = memberOrderList.map((member) => {
      return {
        _id: member._id,
        [MemberFormInputsEnum.Name]: member.fullName,
        [MemberFormInputsEnum.RelationType]: member.relationType,
        [MemberFormInputsEnum.PhoneNumber]: member.phoneNumber,
        [MemberFormInputsEnum.CashAccountId]: member.cashAccount?._id,
        [MemberFormInputsEnum.CashAccount]: member.cashAccount?.account,
        [MemberFormInputsEnum.AccountHolder]: member.cashAccount?.name,
        [MemberFormInputsEnum.Bank]: member.cashAccount?.bank,
        [MemberFormInputsEnum.AccountHolderSame]:
          member.fullName === member.cashAccount?.name ?? true,
        isVerified: member.cashAccount?.isVerified ?? false,
        cashAccountModalConfirmed: member.cashAccountModalConfirmed ?? false,
      };
    });
    return memberInputForm;
  } else {
    return vacantMemberInputForm;
  }
};

const FeventMournerInfoForm: FC<Props> = function FeventMournerInfoForm({
  fevent,
  editMode,
}: Props) {
  const { bugoRole, isAdmin } = useAuth();

  //* useForm 선언
  const { register, handleSubmit, control, setValue } = useForm<MemberIFormInputs>({
    defaultValues: {
      member: defaultMemberInputForm(fevent),
    },
  });
  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { isLoading: processingMembers, mutateAsync } = useMutation(
    defaultApiMutationOptions,
    {
      onSuccess: () => {
        notificateSuccess(
          notifiacationInstance,
          editMode ? '상주정보 수정 완료' : '상주정보 등록 완료',
        );
        if (fevent) {
          queryClient.invalidateQueries(feventHookUrl(fevent._id, bugoRole()));
        }
        queryClient.invalidateQueries(
          activeFeventListForWorkerHookUrl(bugoRole(), isAdmin()),
        );
      },
      onError: () => {
        notificateError(notifiacationInstance, '상주정보 저장 실패');
      },
    },
  );
  const [, setSearchParams] = useSearchParams();

  const setCashAccountByFevent = useSetRecoilState(cashAccountListByFeventAtom);
  const setFeventId = useSetRecoilState(feventIdInMemberListEditorAtom);

  //! fevent member 수정 함수
  const onSubmit: SubmitHandler<MemberIFormInputs> = useCallback(
    async (data) => {
      try {
        await mutateAsync(mournerInfoFormSubmit(data, fevent, bugoRole()));

        if (!editMode) {
          setSearchParams({ feventId: fevent._id, step: '2' });
        } else {
          {
            navigate(`/fevent/${fevent._id}/obituary-send`);
          }
        }
      } catch (err: any) {
        console.error(err?.response?.data);
      }
    },
    [bugoRole, editMode, fevent, mutateAsync, navigate, setSearchParams],
  );

  useEffect(() => {
    if (fevent.cashAccounts) {
      setCashAccountByFevent(fevent.cashAccounts);
    }
    if (fevent) {
      setFeventId(fevent._id);
    }
  }, [fevent, setCashAccountByFevent, setFeventId]);

  return (
    <div className="text-sm">
      <h4 className="ml-2 mb-3 font-bold">부고 전송을 위해 상주 정보를 입력해주세요.</h4>
      <form className="space-y-8">
        <MemberListEditor
          control={control}
          register={register}
          setValue={setValue}
          editMode={editMode}
        />
        <div className="space-y-4">
          <Button
            onClick={handleSubmit(onSubmit)}
            disabled={processingMembers}
            className="button-rectangle text-white theme-bg-main"
          >
            {editMode ? '수정완료' : '다음'}
          </Button>
          {editMode && (
            <div>
              <Link to="/fevent/manage">
                <Button className="button-rectangle text-white theme-bg-12">
                  돌아가기
                </Button>
              </Link>
            </div>
          )}
        </div>
      </form>
      <LoadingModal
        open={processingMembers}
        title={editMode ? '상주정보 수정중' : '상주정보 등록중'}
      />
    </div>
  );
};

export default FeventMournerInfoForm;
