import { FC, useCallback, useEffect, useState } from 'react';

import { CustomCallback } from '@shared/types';

import { CheckBox } from './CheckBox';

export type CheckBoxFilter = {
  [key: string]: boolean;
};

interface IProps {
  filter: CheckBoxFilter;
  setFilter: CustomCallback<CheckBoxFilter, any>;
}

const CheckboxFilter: FC<IProps> = ({ filter, setFilter }: IProps) => {
  const [allCheck, setAllCheck] = useState<boolean>(false);

  const onCheckBoxCheckedChange = useCallback(
    (checked: boolean, key: string) => {
      const result: CheckBoxFilter = { ...filter };
      result[key] = checked;
      setFilter(result);
    },
    [filter, setFilter],
  );

  const onAllCheckBoxClick = (checked: boolean) => {
    const result: CheckBoxFilter = { ...filter };

    if (checked) Object.keys(result).forEach((key) => (result[key] = true));
    else Object.keys(result).forEach((key) => (result[key] = false));

    setAllCheck(checked);
    setFilter(result);
  };

  useEffect(() => {
    if (Object.values(filter).includes(false)) setAllCheck(false);
    else setAllCheck(true);
  }, [filter]);

  return (
    <>
      <label key={'check-box-filter-all-check'} className="center-box space-x-2 py-2">
        <CheckBox
          checked={allCheck}
          onCheckedChange={onAllCheckBoxClick}
          className="h-4 w-4"
        />
        <p className="break-keep font-medium">전체</p>
      </label>
      {Object.entries(filter).map(([key, value]) => (
        <label
          key={'check-box-filter-' + key}
          className="center-box cursor-pointer space-x-2 py-2"
        >
          <CheckBox
            checked={value}
            onCheckedChange={(checked) => onCheckBoxCheckedChange(checked, key)}
            className="h-4 w-4"
          />
          <p className="break-keep font-medium">{key}</p>
        </label>
      ))}
    </>
  );
};

export default CheckboxFilter;
