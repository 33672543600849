import { forwardRef, InputHTMLAttributes } from 'react';

import { useId } from 'react-id-generator';
export interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  labelClassname?: string;
  required?: boolean;
}

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      label,
      required,
      labelClassname,
      className,
      children,
      autoComplete = 'off',
      ...props
    },
    ref,
  ) => {
    const [id] = useId(1, 'textfield');
    return (
      <div className="label-col">
        {label && <p className={`${labelClassname} label`}>{label}</p>}
        <div className="relative">
          <input
            autoComplete={autoComplete}
            {...props}
            ref={ref}
            id={id}
            className={`${className} ${
              required ? 'border-warn' : 'theme-border-1'
            } textfield theme-text-1 theme-bg-1 placeholder:theme-text-6 `}
          />
          {children}
        </div>
        {/* {helper && <p className="text-sm text-error">{helper}</p>} */}
      </div>
    );
  },
);
