import { FC } from 'react';

import StatPageTableHeaderBox from '@service/bugo/components/StatPageTableHeaderBox';
import { BugoStatRangeQueryEnum } from '@service/bugo/page/AgencyAdminBugoStatMobilePage';
import { FeventByBugoBrand } from '@shared/api/fevent/fevent.interface';
import { WorkerUser } from '@shared/api/user/user.interface';
import { CheckBoxFilter } from '@shared/components/CheckboxFilter';
import {
  getThisMonthFrom,
  getThisWeekFrom,
  getThisYearFrom,
  getTodayFrom,
} from '@shared/utils/dateUtils';
import { useSearchParams } from 'react-router-dom';

import AgencyAdminFeventTable from './AgencyAdminFeventTable';
import AgencyAdminRankTable from './AgencyAdminRankTable';

interface IProps {
  workerUserList: WorkerUser[];
  feventList: FeventByBugoBrand[];
  isBugoTable: boolean;
  range: BugoStatRangeQueryEnum | null;
  teamTypesFilter: CheckBoxFilter;
  regionsFilter: CheckBoxFilter;
}

const getYear = (feventList: FeventByBugoBrand[]) => {
  const result = feventList.filter((fevent) => {
    const date = new Date(fevent.createdAt);
    const targetDate = getThisYearFrom(new Date());
    const now = new Date();
    return targetDate.getTime() < date.getTime() && date.getTime() < now.getTime();
  });
  return result.length;
};

const getToday = (feventList: FeventByBugoBrand[]) => {
  const result = feventList.filter((fevent) => {
    const date = new Date(fevent.createdAt);
    const targetDate = getTodayFrom(new Date());
    const now = new Date();
    return targetDate.getTime() < date.getTime() && date.getTime() < now.getTime();
  });
  return result.length;
};

const getWeek = (feventList: FeventByBugoBrand[]) => {
  const result = feventList.filter((fevent) => {
    const date = new Date(fevent.createdAt);
    const targetDate = getThisWeekFrom(new Date());
    const now = new Date();
    return targetDate.getTime() < date.getTime() && date.getTime() < now.getTime();
  });
  return result.length;
};

const getMonth = (feventList: FeventByBugoBrand[]) => {
  const result = feventList.filter((fevent) => {
    const date = new Date(fevent.createdAt);
    const targetDate = getThisMonthFrom(new Date());
    const now = new Date();
    return targetDate.getTime() < date.getTime() && date.getTime() < now.getTime();
  });
  return result.length;
};

const AgencyAdminBugoListTable: FC<IProps> = ({
  workerUserList,
  feventList,
  isBugoTable,
  range,
  teamTypesFilter,
  regionsFilter,
}: IProps) => {
  const [, setSearchParams] = useSearchParams();

  return (
    <div className="space-y-4">
      <div className="flex gap-8">
        <button
          className="flex-1"
          onClick={() => setSearchParams({ range: BugoStatRangeQueryEnum.Day })}
        >
          <StatPageTableHeaderBox
            left="금일"
            right={getToday(feventList).toString() + '건'}
            isChecked={range === BugoStatRangeQueryEnum.Day}
          />
        </button>
        <button
          className="flex-1"
          onClick={() => setSearchParams({ range: BugoStatRangeQueryEnum.Week })}
        >
          <StatPageTableHeaderBox
            left="금주"
            right={getWeek(feventList).toString() + '건'}
            isChecked={range === BugoStatRangeQueryEnum.Week}
          />
        </button>
        <button
          className="flex-1"
          onClick={() => setSearchParams({ range: BugoStatRangeQueryEnum.Month })}
        >
          <StatPageTableHeaderBox
            left="금월"
            right={getMonth(feventList).toString() + '건'}
            isChecked={range === BugoStatRangeQueryEnum.Month}
          />
        </button>
        <button
          className="flex-1"
          onClick={() => setSearchParams({ range: BugoStatRangeQueryEnum.Year })}
        >
          <StatPageTableHeaderBox
            left="연간"
            right={getYear(feventList).toString() + '건'}
            isChecked={range === BugoStatRangeQueryEnum.Year}
          />
        </button>
      </div>
      {/* Tables */}
      {isBugoTable ? (
        <AgencyAdminFeventTable
          feventList={feventList}
          range={range}
          teamTypesFilter={teamTypesFilter}
          regionsFilter={regionsFilter}
        />
      ) : (
        <AgencyAdminRankTable
          workerUserList={workerUserList}
          range={range}
          teamTypesFilter={teamTypesFilter}
        />
      )}
    </div>
  );
};

export default AgencyAdminBugoListTable;
