import { FeventDate } from '@shared/types';

import { DAY_LIST } from './dayList';

interface IDateFormatOptions {
  dateSeparater?: string; // default: "-"
  timeSeparater?: string; // default: ":"
  contains?: {
    year?: boolean; // default: true
    month?: boolean; // default: true
    date?: boolean; // default: true
    day?: boolean; // default: false
    amPm?: boolean; // default: false
    hours?: boolean; // default: false
    minutes?: boolean; // default: false
    seconds?: boolean; // default: false
  };
}

const prependZero = (num: number): string => (num < 10 ? `0${num}` : String(num));

const getHours = (hours: number, amPm: '' | '오전' | '오후') => {
  let result: number;
  switch (amPm) {
    case '':
      result = hours;
      break;
    case '오전':
      result = hours;
      break;
    case '오후':
      result = hours === 12 ? hours : hours - 12;
      break;
    default:
      result = hours;
      break;
  }
  return prependZero(result);
};

export const formatDate = (
  targetDate: Date | null | undefined,
  options?: IDateFormatOptions,
): string => {
  if (!targetDate) return '미정';
  targetDate = new Date(targetDate);

  // default options
  let dateSeparater = '-',
    timeSeparater = ':',
    yearOpt = true,
    monthOpt = true,
    dateOpt = true,
    dayOpt = false,
    // amPmOpt = false,
    hoursOpt = false,
    minutesOpt = false,
    secondsOpt = false;

  if (options) {
    dateSeparater = options.dateSeparater ?? dateSeparater;
    timeSeparater = options.timeSeparater ?? timeSeparater;
    if (options.contains) {
      yearOpt = options.contains.year ?? yearOpt;
      monthOpt = options.contains.month ?? monthOpt;
      dateOpt = options.contains.date ?? dateOpt;
      dayOpt = options.contains.day ?? dayOpt;
      // amPmOpt = options.contains.amPm ?? amPmOpt;
      hoursOpt = options.contains.hours ?? hoursOpt;
      minutesOpt = options.contains.minutes ?? minutesOpt;
      secondsOpt = options.contains.seconds ?? secondsOpt;
    }
  }

  const year = yearOpt ? prependZero(targetDate.getFullYear()) : '';
  const month = monthOpt ? prependZero(targetDate.getMonth() + 1) : '';
  const date = dateOpt ? prependZero(targetDate.getDate()) : '';
  const day = dayOpt ? `(${DAY_LIST[targetDate.getDay()]})` : '';
  // const amPm = amPmOpt ? getAmPm(targetDate) : '';
  const hours = hoursOpt ? prependZero(targetDate.getHours()) : '';
  const minutes = minutesOpt ? prependZero(targetDate.getMinutes()) : '';
  const seconds = secondsOpt ? prependZero(targetDate.getSeconds()) : '';

  let result = '';

  // Year
  result += year;
  // Month
  result += result.length !== 0 && month.length !== 0 ? dateSeparater : '';
  result += month;
  // Date
  result += result.length !== 0 && date.length !== 0 ? dateSeparater : '';
  result += date;
  // Day
  result += day;

  result +=
    result.length !== 0 &&
    (hours.length !== 0 || minutes.length !== 0 || seconds.length !== 0)
      ? ' '
      : '';

  // amPm
  // result += amPm + ' ';
  // Hours
  result += hours;
  // Minutes
  // result += result.length !== 0 && minutes.length !== 0 ? timeSeparater : '';
  result += result.length !== 0 && minutes.length !== 0 ? timeSeparater ?? '시 ' : '';
  result += minutes;
  result +=
    result.length !== 0 && minutes.length !== 0 ? (timeSeparater ? '' : '분 ') : '';

  // Seconds
  // result += result.length !== 0 && seconds.length !== 0 ? timeSeparater : '';
  result += seconds;

  return result;
};

export const formatFeventDateString = (fDate: FeventDate, timeShow = true) => {
  const dateArr: string[] = [];
  if (fDate && fDate.date && fDate.timeDefined) {
    dateArr.push(`${fDate.date.getFullYear()}년`);
    dateArr.push(`${fDate.date.getMonth() + 1}월`);
    dateArr.push(`${fDate.date.getDate()}일`);
    if (timeShow) {
      dateArr.push(
        `${
          fDate.date.getHours() >= 10
            ? fDate.date.getHours()
            : '0' + fDate.date.getHours()
        }시`,
      );
      dateArr.push(
        `${
          fDate.date.getMinutes() >= 10
            ? fDate.date.getMinutes()
            : '0' + fDate.date.getMinutes()
        }분`,
      );
    }
  } else if (fDate && fDate.date && !fDate.timeDefined) {
    dateArr.push(`${fDate.date.getFullYear()}년`);
    dateArr.push(`${fDate.date.getMonth() + 1}월`);
    dateArr.push(`${fDate.date.getDate()}일`);
    if (timeShow) {
      dateArr.push('시간 미정');
    }
  } else {
    dateArr.push('미정');
  }
  return dateArr.join(' ');
};

export const getAfter5days = (date: Date | null) => {
  if (date) {
    const dateTemp = new Date(date);
    dateTemp.setDate(dateTemp.getDate() + 5);
    return dateTemp;
  }
};
