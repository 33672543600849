//* member(상주) 에디터 FeventNew, FeventEdit 시 사용함
import { FC, useState } from 'react';

import {
  MemberFormInputsEnum,
  MemberIFormInputs,
} from '@service/bugo/interfaces/memberAddEdit.interface';
import { Button } from '@shared/components/Button';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import {
  Control,
  UseFormRegister,
  UseFormSetValue,
  useFieldArray,
} from 'react-hook-form';

import MemberInfoCard from '../MemberInfoCard/MemberInfoCard';

interface Props {
  control: Control<MemberIFormInputs, any>;
  setValue: UseFormSetValue<MemberIFormInputs>;
  register: UseFormRegister<MemberIFormInputs>;
  editMode?: boolean;
}

export const MemberListEditor: FC<Props> = function MemberListEditor({
  control,
  setValue,
  register,
  editMode,
}) {
  const { fields, remove, update, move, append } = useFieldArray({
    control,
    name: 'member',
  });

  //* 상주 추가
  const addMember = () => {
    append({
      _id: '',
      [MemberFormInputsEnum.Name]: '',
      [MemberFormInputsEnum.RelationType]: '',
      [MemberFormInputsEnum.PhoneNumber]: '',
      [MemberFormInputsEnum.CashAccount]: '',
      [MemberFormInputsEnum.AccountHolder]: '',
      [MemberFormInputsEnum.Bank]: '',
      [MemberFormInputsEnum.AccountHolderSame]: true,
      isVerified: false,
      cashAccountModalConfirmed: false,
      isNew: true,
    });
  };

  //* 상주 드래그 함수
  const onDragEnd = function (result: DropResult) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    move(result.source.index, result.destination.index);
  };

  const [foldAll, setFoldAll] = useState(editMode ?? false);

  return (
    <div className="space-y-3">
      <div className="center-box">
        <Button
          type="button"
          className="button-fold"
          onClick={() => {
            setFoldAll(!foldAll);
          }}
        >
          전체 펼치기/접기
        </Button>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <ul>
          <Droppable droppableId="fields">
            {(provided) => (
              <div
                className="fields space-y-3"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {fields.map((item, index) => {
                  return (
                    <Draggable
                      key={`drag-item-${index}`}
                      draggableId={`drag-item-${index}`}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          <MemberInfoCard
                            key={index}
                            memberItem={item}
                            index={index}
                            control={control}
                            setValue={setValue}
                            register={register}
                            update={update}
                            remove={remove}
                            foldAll={foldAll}
                          ></MemberInfoCard>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </ul>
      </DragDropContext>
      <div className="center-box">
        <Button
          type="button"
          className="button-fold"
          onClick={() => {
            addMember();
          }}
        >
          상주 등록 추가
        </Button>
      </div>
    </div>
  );
};
