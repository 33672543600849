import { FC } from 'react';

import { useAuth } from '@shared/state/hooks/useAuth';
import { Link, useLocation, useParams } from 'react-router-dom';

import { Icon } from './icons';

const linkColorGenerator = (isAccent: boolean) =>
  isAccent ? 'theme-bg-main text-white' : 'theme-text-main';

const StatPageSideBar: FC = () => {
  const { bugoBrandId } = useParams();
  const { pathname } = useLocation();

  const { WreathBig, BugoMessage } = Icon();
  const { userProfile } = useAuth();

  const bugoBrand = userProfile?.bugoAgencyAdminDetail?.bugoBrands[0];

  return (
    <aside className="w-60 flex-1 bg-white p-4">
      {/* 회사 / 유저 정보 */}
      <div className="space-y-2 border-b border-gray-300 py-4 font-bold">
        {/* <h6 className="px-2 text-xs text-gray-500">
      {userProfile?.bugoAgencyAdminDetail?.bugoBrands[0]?.corpName}
    </h6>
    <h4 className="rounded-md bg-[#F3F6FE] p-3 text-daeMyung">
      {userProfile?.bugoAgencyAdminDetail?.bugoBrands[0]?.name}
    </h4> */}
        <h6 className="px-2 text-xs text-gray-500">{bugoBrand?.bugoAgency?.corpName}</h6>
        <h4 className="rounded-md bg-[#F3F6FE] p-3 theme-text-main">
          {bugoBrand?.bugoAgency?.nickName} 관리자
        </h4>
      </div>
      {/* 버튼 리스트 */}
      <div className="space-y-3 py-4 text-xs font-bold">
        <div>
          <Link
            to={`/bugo-brand/${bugoBrandId}/flower-stat`}
            className={`flex items-center space-x-3 rounded-md p-2 ${linkColorGenerator(
              pathname.endsWith('flower-stat'),
            )}`}
          >
            <WreathBig className="m-1" />
            <h5>화환 내역</h5>
          </Link>
        </div>
        <div>
          <Link
            to={`/bugo-brand/${bugoBrandId}/bugo-stat`}
            className={`flex items-center space-x-3 rounded-md p-2 ${linkColorGenerator(
              pathname.endsWith('bugo-stat'),
            )}`}
          >
            <BugoMessage />
            <h5>부고 발송 내역</h5>
          </Link>
        </div>
      </div>
    </aside>
  );
};

export default StatPageSideBar;
