import { FC } from 'react';

import { IconFormer } from '@shared/components/IconsFormer';
import { Sidebar } from '@shared/components/Sidebar';

// const { Delivery, Flower, Modify, Receipt, Home, Question, Setting } = Icon();

const navigation = (shopId: string) => [
  { name: 'Home', href: `/flower-seller/shop/${shopId}`, icon: IconFormer.Home },
  {
    name: '주문배송관리',
    href: `/flower-seller/shop/${shopId}/delivery-manage`,
    icon: IconFormer.DeliveryBlue,
  },

  {
    name: '부고조회',
    href: `/flower-seller/shop/${shopId}/bugo-search`,
    icon: IconFormer.DPBlue,
  },
  {
    name: '입금대기',
    href: `/flower-seller/shop/${shopId}/waiting-list`,
    icon: IconFormer.Loading,
  },
  {
    name: '상품메뉴관리',
    href: `/flower-seller/shop/${shopId}/shop-item`,
    icon: IconFormer.Flower,
  },
  {
    name: '정산',
    href: `/flower-seller/shop/${shopId}/account`,
    icon: IconFormer.ModifyBlue,
  },
  {
    name: '판매자 정보',
    href: `/flower-seller/shop/${shopId}/info`,
    icon: IconFormer.UserBlue,
  },
];
const secondaryNavigation = (shopId: string) => [
  {
    name: '설정',
    href: `/flower-seller/shop/${shopId}/settings`,
    icon: IconFormer.Setting,
  },
  { name: 'Help', href: `/flower-seller/shop/${shopId}/help`, icon: IconFormer.Question },
];

interface Props {
  shopId: string;
}

export const SellerSidebar: FC<Props> = function SellerSidebar({ shopId }: Props) {
  return (
    <Sidebar
      navigation={navigation(shopId)}
      secondaryNavigation={secondaryNavigation(shopId)}
    />
  );
};
