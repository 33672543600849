//*상주용 부고 공유 페이지(개인정보 동의, 부의금 정보)

import { FC, useEffect, useState } from 'react';

import { useSetRecoilState } from 'recoil';

import { BugoData } from '@shared/api/bugo/bugo.interface';
import { updateMember } from '@shared/api/member/member.controller';
import { MemberUpdate } from '@shared/api/member/member.interface';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { LowerRole } from '@shared/types';
import { useNavigate } from 'react-router-dom';

import Obituary from '../Obituary/Obituary';
import { MemberCashAccountModal } from './MemberCashAccountModal';
import { MemberPrivacyAgreementModal } from './MemberPrivacyAgreementModal';

interface Props {
  bugo: BugoData;
}

const MemberObituary: FC<Props> = function MemberObituary({ bugo }: Props) {
  const navigate = useNavigate();

  const [privacyAgreementOpen, setPrivacyAgreementOpen] = useState<boolean>(
    !bugo.member.privacyAgreement,
  );

  const cashAccountOpenId = `memberObituary-cashAccountOpen-${bugo._id}`;

  const setCashAccountModalOpen = useSetRecoilState<boolean>(
    simpleOpenAtomFamily(cashAccountOpenId),
  );

  useEffect(() => {
    setCashAccountModalOpen(
      !bugo.member.cashAccountModalConfirmed && !privacyAgreementOpen,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPrivacyAgree = () => {
    setPrivacyAgreementOpen(false);
    setCashAccountModalOpen(true);

    //* 동의여부 업데이트는 await할 필요가 없어보임.
    const memberUpdate: MemberUpdate = {
      _id: bugo.member._id,
      privacyAgreement: true,
    };
    updateMember(memberUpdate, LowerRole.Public);
  };

  const onPrivacyCancle = () => {
    navigate(-1);
  };

  const closeCashAccountModal = () => setCashAccountModalOpen(false);

  return (
    <>
      <Obituary bugo={bugo} forMember={true} />
      {/* 개인정보동의 Modal */}
      <MemberPrivacyAgreementModal
        open={privacyAgreementOpen}
        cancelBtnCallback={onPrivacyCancle}
        agreeBtnCallback={onPrivacyAgree}
        bugoBrand={bugo.bugoBrand}
      />
      {/* 부의금 모달 */}
      <MemberCashAccountModal
        openId={cashAccountOpenId}
        closeModal={closeCashAccountModal}
        bugo={bugo}
      />
    </>
  );
};

export default MemberObituary;
