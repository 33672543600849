import { atom, atomFamily } from 'recoil';

import { QueryStatus } from 'react-query';

import {
  FeventByBugoBrand,
  FeventByShop,
  FeventDoingList,
  FeventList,
  FeventOne,
} from './fevent.interface';

export const feventStatusAtomFamily = atomFamily<QueryStatus, string>({
  key: 'feventStatusAtomFamily',
  default: 'idle',
});

export const feventDataAtomFamily = atomFamily<FeventOne | null, string>({
  key: 'feventDataAtomFamily',
  default: null,
});

export const feventListStatusAtom = atom<QueryStatus>({
  key: 'feventListStatusAtom',
  default: 'idle',
});

export const feventListDataAtom = atom<FeventList | null>({
  key: 'feventListDataAtom',
  default: null,
});

export const activeFeventListForWorkerStatusAtom = atom<QueryStatus>({
  key: 'activeFeventListForWorkerStatusAtom',
  default: 'idle',
});

export const activeFeventListForWorkerDataAtom = atom<FeventList | null>({
  key: 'activeFeventListForWorkerDataAtom',
  default: null,
});

export const feventDoingListStatusAtom = atom<QueryStatus>({
  key: 'feventDoingListStatusAtom',
  default: 'idle',
});

export const feventDoingListDataAtom = atom<FeventDoingList | null>({
  key: 'feventDoingListDataAtom',
  default: null,
});

export const feventListByBugoBrandStatusAtomFamily = atomFamily<QueryStatus, string>({
  key: 'feventListByBugoBrandStatusAtomFamily',
  default: 'idle',
});

export const feventListByBugoBrandDataAtomFamily = atomFamily<
  FeventByBugoBrand[] | null,
  string
>({
  key: 'feventListByBugoBrandDataAtomFamily',
  default: null,
});

export const feventCountByBugoBrandStatusAtomFamily = atomFamily<QueryStatus, string>({
  key: 'feventCountByBugoBrandStatusAtomFamily',
  default: 'idle',
});

export const feventCountByBugoBrandDataAtomFamily = atomFamily<number | null, string>({
  key: 'feventCountByBugoBrandDataAtomFamily',
  default: null,
});

//* seller 용 부고 검색
export const feventByShopStatusAtomFamily = atomFamily<QueryStatus, string>({
  key: 'feventByShopStatusAtomFamily',
  default: 'idle',
});

export const feventByShopDataAtomFamily = atomFamily<FeventByShop[] | null, string>({
  key: 'feventByShopDataAtomFamily',
  default: null,
});
