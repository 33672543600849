import { fetcher, queryString, useRecoilQuery } from '@shared/hooks/recoil-query';

import { bugoBrandListDataAtom, bugoBrandListStatusAtom } from './bugoBrand.atom';
export const bugoBrandListHookUrl = `admin/bugo-brand?${queryString({
  populate: [
    {
      path: 'workers',
    },
    {
      path: 'bugoAgency.headerImage',
    },
    {
      path: 'bugoAgency.footerImage',
    },
    { path: 'bugoAgency.rebate' },
  ],
})}`;

export const useBugoBrandListHook = () => {
  useRecoilQuery(
    bugoBrandListStatusAtom,
    bugoBrandListDataAtom,
    bugoBrandListHookUrl,
    fetcher,
  );
};
