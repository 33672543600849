import { FC } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { getShopItemImageUrl } from '@shared/api/shopItem/shopItem.utils';
import { patchOrderWaitingToPaid } from '@shared/api/shopOrder/shopOrder.controller';
import { shopOrderWaitingListForSellerByShopUrl } from '@shared/api/shopOrder/shopOrder.hook';
import { ShopOrderForSeller } from '@shared/api/shopOrder/shopOrder.interface';
import { Button } from '@shared/components/Button';
import ConfirmModal from '@shared/components/ConfirmModal';
import { notificateSuccess } from '@shared/plugIn/ant-notification/ant-notifiaction';
import { defaultApiMutationOptions } from '@shared/plugIn/apiMutation';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { notificationInstanceAtom } from '@shared/state/atom/notification.atom';
import { useAuth } from '@shared/state/hooks/useAuth';
import { fileBucketUrl } from '@shared/utils/fileUtils';
import { formatDate } from '@shared/utils/formatDate';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { ShopOrderDetailInfoCard } from './ShopOrderDetailInfoCard';

interface Props {
  shopOrder: ShopOrderForSeller;
}

export const WaitingShopOrderCard: FC<Props> = function ({ shopOrder }: Props) {
  const deliveryDetail = shopOrder.deliveryDetail;
  const shopItem = shopOrder.orderDetail.shopItem;
  const openId = `waitingShopOrderCard-${shopOrder._id}`;
  const { shopId } = useParams();
  const setOpen = useSetRecoilState(simpleOpenAtomFamily(openId));
  const queryClient = useQueryClient();
  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);

  const { sellerRole } = useAuth();

  const { isLoading: updatingOrder, mutate } = useMutation(defaultApiMutationOptions, {
    onSuccess: () => {
      queryClient.invalidateQueries(
        shopOrderWaitingListForSellerByShopUrl(`${shopId}`, sellerRole()),
      );
      notificateSuccess(notifiacationInstance, '입금확인완료');
    },
  });

  const onPaidUpdateClick = async () => {
    try {
      const shopOrderUpdate = await patchOrderWaitingToPaid(shopOrder._id, sellerRole());
      console.log(shopOrderUpdate);
    } catch (err: any) {
      console.error('error');
    }
  };

  return (
    <div key={shopOrder._id} className="relative">
      <div className="h-auto w-full rounded-lg border shadow">
        <div className="p-5 pb-3">
          <div className="flex items-center">
            <div className="wh-20 shrink-0 overflow-hidden">
              <img
                src={fileBucketUrl(
                  getShopItemImageUrl(shopOrder.orderDetail.shopItem),
                  'w200',
                )}
                alt="productImg"
              />
            </div>
            <div className="ml-5 w-0 flex-1">
              <dl>
                <dt className="truncate text-lg font-medium text-gray-900">
                  {shopItem.name}
                </dt>
                <dt className="truncate text-base font-medium text-gray-900">
                  {shopItem.priceRetail.toLocaleString()}원
                </dt>
                <dd className="space-y-0.5">
                  <p className="whitespace-pre-wrap text-sm text-gray-700">
                    {deliveryDetail.receiverAddress}
                  </p>
                  <p className="text-sm text-gray-700">
                    주문:{' '}
                    {formatDate(shopOrder.paymentDetail.requestedAt, {
                      dateSeparater: '.',
                      contains: {
                        year: false,
                        day: true,
                        hours: true,
                        minutes: true,
                      },
                    })}
                  </p>
                </dd>
              </dl>
            </div>
          </div>
          <div className="flex items-center justify-between space-x-3 pt-3">
            <p className="font-bold">입금대기중</p>
            <Button
              className="button-sm bg-cyan-600 text-white hover:bg-cyan-700"
              onClick={() => {
                setOpen(true);
              }}
            >
              입금완료
            </Button>
            <ConfirmModal
              openId={openId}
              buttonTitle="확인"
              title="입금완료확인"
              onClick={async () => {
                mutate(onPaidUpdateClick());
              }}
            ></ConfirmModal>
          </div>
        </div>
        <div className="space-y-4 border-t border-gray-300 bg-gray-50 px-5 py-3 pt-6">
          <div>
            <div className="py-2">
              <p className="-mt-4 font-medium">주문번호: {shopOrder._id}</p>
            </div>
            <div>
              <ShopOrderDetailInfoCard shopOrder={shopOrder} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
