import { BankEnum } from '@shared/miscValues';
import BNP파리바 from 'src/assets/logo/bank/BNP파리바.png';
import BOA from 'src/assets/logo/bank/BOA.png';
import HSBC from 'src/assets/logo/bank/HSBC.png';
import IBK기업 from 'src/assets/logo/bank/IBK기업.png';
import JP모간체이스 from 'src/assets/logo/bank/JP모간체이스.png';
import KDB산업 from 'src/assets/logo/bank/KDB산업.png';
import SC제일 from 'src/assets/logo/bank/SC제일.png';
import 경남 from 'src/assets/logo/bank/경남.png';
import 광주 from 'src/assets/logo/bank/광주.png';
import 국민 from 'src/assets/logo/bank/국민.png';
import 농협 from 'src/assets/logo/bank/농협.png';
import 대구 from 'src/assets/logo/bank/대구.png';
import 도이치 from 'src/assets/logo/bank/도이치.png';
import 부산 from 'src/assets/logo/bank/부산.png';
import 산림조합 from 'src/assets/logo/bank/산림조합.png';
import 새마을금고 from 'src/assets/logo/bank/새마을금고.png';
import 수협 from 'src/assets/logo/bank/수협.png';
import 신한 from 'src/assets/logo/bank/신한.png';
import 신협 from 'src/assets/logo/bank/신협.png';
import 우리 from 'src/assets/logo/bank/우리.png';
import 우체국 from 'src/assets/logo/bank/우체국.png';
import 저축 from 'src/assets/logo/bank/저축.png';
import 전북 from 'src/assets/logo/bank/전북.png';
import 제주 from 'src/assets/logo/bank/제주.png';
import 중국건설 from 'src/assets/logo/bank/중국건설.png';
import 중국공상 from 'src/assets/logo/bank/중국공상.png';
import 지역농축협 from 'src/assets/logo/bank/지역농축협.png';
import 카카오뱅크 from 'src/assets/logo/bank/카카오뱅크.png';
import 케이뱅크 from 'src/assets/logo/bank/케이뱅크.png';
import 토스뱅크 from 'src/assets/logo/bank/토스뱅크.png';
import 하나 from 'src/assets/logo/bank/하나.png';
import 한국씨티 from 'src/assets/logo/bank/한국씨티.png';

const bankLogos = {
  국민,
  신한,
  하나,
  우리,
  IBK기업,
  농협,
  KDB산업,
  수협,
  우체국,
  한국씨티,
  SC제일,
  카카오뱅크,
  케이뱅크,
  토스뱅크,
  경남,
  광주,
  대구,
  부산,
  전북,
  제주,
  저축,
  산림조합,
  새마을금고,
  신협,
  도이치,
  중국건설,
  중국공상,
  HSBC,
  BNP파리바,
  JP모간체이스,
  지역농축협,
  BOA,
};

export const getBankLogo = (bankName: BankEnum) => bankLogos[bankName];
