import { FC, useCallback, useMemo, useState } from 'react';

import { useRecoilValue } from 'recoil';

import { bugoBrandListHookUrl } from '@shared/api/bugoBrand/bugoBrand.hook';
import { BugoBrandData } from '@shared/api/bugoBrand/bugoBrand.interface';
import { Rebate } from '@shared/api/rebate/rebate.interface';
import { postImage } from '@shared/api/uploadImage';
import { Button } from '@shared/components/Button';
import { EditableTagGroup } from '@shared/components/EditableTagGroup';
import { SingleImageUploader } from '@shared/components/ImageUploader';
import { Label } from '@shared/components/Label';
import { LoadingModal } from '@shared/components/LoadingModal';
import { TextField } from '@shared/components/TextField';
import Select from '@shared/containers/Select/Select';
import {
  notificateError,
  notificateSuccess,
} from '@shared/plugIn/ant-notification/ant-notifiaction';
import { defaultApiMutationOptions } from '@shared/plugIn/apiMutation';
import { api_ } from '@shared/plugIn/axios';
import { uploadFileAtomFamily } from '@shared/state/atom/file.atom';
import { notificationInstanceAtom } from '@shared/state/atom/notification.atom';
import { useAuth } from '@shared/state/hooks/useAuth';
import { fileBucketUrl } from '@shared/utils/fileUtils';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useAsync } from 'react-use';

import { onBugoBrandUpsert } from './AdminBugoBrand.util';
import { BugoBrandFormInputs, BugoBrandFormInputsEnum } from './BugoBrandForm.interface';

interface Props {
  bugoBrand?: BugoBrandData;
}

//* 관리자용 BugoBrand 수정 및 등록 시 사용할 Form
export const AdminBugoBrandForm: FC<Props> = function AdminBugoBrandForm({
  bugoBrand,
}: Props) {
  const { assetRole } = useAuth();
  const navigate = useNavigate();
  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);
  const [rebateList, setRebateList] = useState<{ value: string; label: string }[]>([]);

  const headerImageAtomId = `bugoBrandForm-headerImage-${bugoBrand?._id}`;
  const footerImageAtomId = `bugoBrandForm-footerImage-${bugoBrand?._id}`;
  const uploadHeaderImageFile = useRecoilValue(uploadFileAtomFamily(headerImageAtomId));
  const uploadFooterImageFile = useRecoilValue(uploadFileAtomFamily(footerImageAtomId));

  useAsync(async () => {
    const { data: rebateList } = await api_.get<Rebate[]>(`admin/rebate`);
    if (rebateList) {
      const rebateOptionList = rebateList.map((rebate) => {
        return {
          value: rebate._id,
          label: rebate.name,
        };
      });
      setRebateList(rebateOptionList);
    }
  }, []);

  const queryClient = useQueryClient();
  const { isLoading, mutate } = useMutation(defaultApiMutationOptions, {
    onSuccess: () => {
      notificateSuccess(notifiacationInstance, '저장 완료');
      queryClient.invalidateQueries(bugoBrandListHookUrl);
    },
    onError: () => {
      notificateError(notifiacationInstance, '상조정보 저장 실패');
    },
  });

  const { register, handleSubmit, control, setValue } = useForm<BugoBrandFormInputs>({
    defaultValues: {
      corpName: bugoBrand?.bugoAgency?.corpName,
      nickName: bugoBrand?.bugoAgency?.nickName,
      phoneNumber: bugoBrand?.bugoAgency?.phoneNumber,
      address: bugoBrand?.bugoAgency?.address,
      headerImage: bugoBrand?.bugoAgency?.headerImage,
      footerImage: bugoBrand?.bugoAgency?.footerImage,
      rebate: bugoBrand?.bugoAgency?.rebate?._id,
      theme: bugoBrand?.bugoAgency?.theme,
      frontUrl: bugoBrand?.bugoAgency?.frontUrl,
      regAlimTalkWebDomain: bugoBrand?.bugoAgency?.regAlimTalkWebDomain,
      regions: bugoBrand?.bugoAgency?.regions,
      teamTyeps: bugoBrand?.bugoAgency?.teamTypes,
      teamNames: bugoBrand?.bugoAgency?.teamNames,
      homepageUrl: bugoBrand?.bugoAgency?.homepageUrl,
      kakaoAppJavascriptKey: bugoBrand?.bugoAgency?.kakaoAppJavascriptKey,
      kakaoTemplateBugoButtonId: bugoBrand?.bugoAgency?.kakaoTemplateBugoButtonId,
    },
  });

  const regionTags =
    useWatch({
      control,
      name: BugoBrandFormInputsEnum.Regions,
      defaultValue: bugoBrand?.bugoAgency?.regions,
    }) ?? [];

  const teamTypeTags =
    useWatch({
      control,
      name: BugoBrandFormInputsEnum.TeamTypes,
      defaultValue: bugoBrand?.bugoAgency?.teamTypes,
    }) ?? [];

  const teamNameTags =
    useWatch({
      control,
      name: BugoBrandFormInputsEnum.TeamNames,
      defaultValue: bugoBrand?.bugoAgency?.teamNames,
    }) ?? [];

  const onAddTag = useCallback(
    (value: string, name: BugoBrandFormInputsEnum, tagList: string[]) => {
      setValue(name, [...tagList, value]);
    },
    [setValue],
  );

  const onDeleteTag = useCallback(
    (value: string, name: BugoBrandFormInputsEnum, tagList: string[]) => {
      const newTagList = tagList.filter((tag) => tag !== value);
      setValue(name, newTagList);
    },
    [setValue],
  );

  const onSubmit: SubmitHandler<BugoBrandFormInputs> = async (data) => {
    try {
      //1. 이미지가 있을 경우
      if (uploadHeaderImageFile) {
        const headerImage = await postImage(uploadHeaderImageFile, assetRole());
        data[BugoBrandFormInputsEnum.HeaderImage] = headerImage;
      }

      if (uploadFooterImageFile) {
        const footerImage = await postImage(uploadFooterImageFile, assetRole());
        data[BugoBrandFormInputsEnum.FooterImage] = footerImage;
      }

      const ret = await onBugoBrandUpsert(data, bugoBrand?._id);
      console.log(ret);
    } catch (err: any) {
      console.error(err);
    }
  };

  const rebateSelect = useMemo(() => {
    if (rebateList) {
      return (
        <div className="label-col">
          <Label>리베이트</Label>
          <Select
            placeholder="리베이트"
            optionList={rebateList}
            control={control}
            name={BugoBrandFormInputsEnum.Rebate}
          ></Select>
        </div>
      );
    } else {
      return <div></div>;
    }
  }, [control, rebateList]);
  const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // 폼 제출 방지
      // 폼 제출 대신 다른 동작 실행
    }
  };
  return (
    <>
      <form onKeyDown={handleKeyDown}>
        <div className="grid grid-cols-2 gap-x-4 gap-y-2">
          <TextField
            className="w-full"
            {...register(BugoBrandFormInputsEnum.CorpName)}
            label={'상조회사명'}
            placeholder="상조회사명"
          ></TextField>
          <TextField
            className="w-full"
            {...register(BugoBrandFormInputsEnum.NickName)}
            label={'별칭'}
            placeholder="별칭"
          ></TextField>
          <TextField
            placeholder="전화번호"
            className="w-full"
            label="전화번호"
            {...register(BugoBrandFormInputsEnum.PhoneNumber)}
          />
          <TextField
            className="w-full"
            label="주소"
            {...register(BugoBrandFormInputsEnum.Address)}
            placeholder="주소"
          />
          <div className="label-col">
            <Label>로고</Label>
            <SingleImageUploader
              uploadFileAtomId={headerImageAtomId}
              formerImageUrl={
                bugoBrand?.bugoAgency?.headerImage.url
                  ? fileBucketUrl(bugoBrand?.bugoAgency?.headerImage.url, 'w400')
                  : undefined
              }
            />
          </div>
          <div className="label-col">
            <Label>하단배너</Label>
            <SingleImageUploader
              uploadFileAtomId={footerImageAtomId}
              formerImageUrl={
                bugoBrand?.bugoAgency?.footerImage?.url
                  ? fileBucketUrl(bugoBrand?.bugoAgency?.footerImage.url, 'w400')
                  : undefined
              }
            />
          </div>
          {rebateSelect}
          <div className="label-col">
            <Label>테마</Label>
            <Select
              placeholder="테마"
              optionList={[
                { value: 'Default', label: '기본' },
                { value: 'Preed', label: '프리드' },
                { value: 'Dark', label: '다크' },
                { value: 'Daemyung', label: '대명' },
              ]}
              control={control}
              name={BugoBrandFormInputsEnum.Theme}
            ></Select>
          </div>
          <TextField
            className="w-full"
            label="URL"
            placeholder="URL"
            {...register(BugoBrandFormInputsEnum.FrontUrl)}
          />
          <TextField
            className="w-full"
            label="알림도메인"
            {...register(BugoBrandFormInputsEnum.RegAlimTalkWebDomain)}
          />
          <div className="label-col col-span-2">
            <Label>지역</Label>
            <div className="checkbox-container">
              <EditableTagGroup
                tags={regionTags}
                onAdd={(value: string) => {
                  onAddTag(value, BugoBrandFormInputsEnum.Regions, regionTags);
                }}
                onClose={(value: string) => {
                  onDeleteTag(value, BugoBrandFormInputsEnum.Regions, regionTags);
                }}
              />
            </div>
          </div>
          <div className="label-col col-span-2">
            <Label>팀타입</Label>
            <div className="checkbox-container">
              <EditableTagGroup
                tags={teamTypeTags}
                onAdd={(value: string) => {
                  onAddTag(value, BugoBrandFormInputsEnum.TeamTypes, teamTypeTags);
                }}
                onClose={(value: string) => {
                  onDeleteTag(value, BugoBrandFormInputsEnum.TeamTypes, teamTypeTags);
                }}
              />
            </div>
          </div>
          <div className="label-col col-span-2">
            <Label>팀네임</Label>
            <div className="checkbox-container">
              <EditableTagGroup
                tags={teamNameTags}
                onAdd={(value: string) => {
                  onAddTag(value, BugoBrandFormInputsEnum.TeamNames, teamNameTags);
                }}
                onClose={(value: string) => {
                  onDeleteTag(value, BugoBrandFormInputsEnum.TeamNames, teamNameTags);
                }}
              />
            </div>
          </div>
          <div className="col-span-2">
            <TextField
              className="w-full"
              label="홈페이지URL"
              placeholder="홈페이지URL"
              {...register(BugoBrandFormInputsEnum.HomePageUrl)}
            />
          </div>
          <TextField
            className="w-full"
            label="kakaoAppKey"
            placeholder="kakaoAppKey"
            {...register(BugoBrandFormInputsEnum.KakaoAppJavascriptKey)}
          />
          <TextField
            className="w-full"
            label="부고버튼Id"
            placeholder="부고버튼Id"
            {...register(BugoBrandFormInputsEnum.KakaoTemplateBugoButtonId)}
          />
        </div>

        <div className="flex flex-col-reverse gap-4 pt-6 sm:grid sm:grid-cols-2 sm:gap-4">
          <Button
            type="button"
            className="button-rectangle w-full theme-text-3 theme-bg-12"
            onClick={() => {
              navigate(-1);
            }}
          >
            취소
          </Button>
          <Button
            disabled={isLoading}
            className="button-rectangle w-full theme-text-3 theme-bg-main"
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              mutate(handleSubmit(onSubmit)(e));
            }}
          >
            저장
          </Button>
        </div>
      </form>
      <LoadingModal open={isLoading} title={'저장중'} />
    </>
  );
};
