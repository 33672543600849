import { FC, useCallback } from 'react';

import { useSetRecoilState } from 'recoil';

import { BottomPopup } from '@shared/components/BottomPopup';
import { Icon } from '@shared/components/icons';
import Modal from '@shared/components/Modal';
import { ISelectOption, ISelectProps } from '@shared/interfaces/select.interface';
import { bankCategoryList, BankEnum } from '@shared/miscValues';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { getBankLogo } from '@shared/utils/getBankLogo';
import { Controller } from 'react-hook-form';
import { useId } from 'react-id-generator';
import { useMediaQuery } from 'react-responsive';

/**
 * @param placeholder
 * @param optionList select의 option들.
 * @param control from react-hook-form
 * @param name from react-hook-form
 * @returns Select for desktop
 */

interface IBankSelectOption extends ISelectOption {
  value: BankEnum;
}

type Props = Omit<ISelectProps, 'optionList'>;

const getBankList = (): IBankSelectOption[] =>
  bankCategoryList.map((bank) => ({
    label: bank,
    value: bank,
  }));

const BankSelect: FC<Props> = ({
  placeholder,
  control,
  name,
  disabled,
  defaultValue = '',
}: Props) => {
  const options = getBankList();
  const isMobile = !useMediaQuery({
    query: '(min-width: 768px)',
  });
  const { ChevronDown } = Icon();
  const [id] = useId(1, 'bankSelect');
  const openId = `bankSelect-${id}`;
  const setOpen = useSetRecoilState(simpleOpenAtomFamily(openId));

  const modalContents = useCallback(
    (value: any, onChange: (...event: any[]) => void, containerClassName = '') => {
      const onConfirm = (option: ISelectOption, onChange: (...event: any[]) => void) => {
        // set value in react hook form
        onChange(option.value);
        // close modal
        setOpen(false);
      };

      return (
        <div
          className={`space-y-3 p-6 text-sm theme-text-1 theme-bg-1 ${containerClassName}`}
        >
          {/* Title */}
          <h4 className="text-center font-bold">{placeholder}</h4>
          {/* Item List */}
          <ul className="grid h-[600px] max-h-[calc(100vh-80px)] grid-cols-2 overflow-y-auto pt-3 pb-4">
            {options.map((option, index) => (
              <li
                key={`${index}-${option.value}-${option.label}`}
                onClick={() => onConfirm(option, onChange)}
                className={`flex cursor-pointer items-center justify-start gap-2 px-4 py-2.5 theme-border-1 ${
                  option.value === value ? 'text-white theme-bg-main' : 'hover:theme-bg-5'
                }`}
              >
                <img
                  src={getBankLogo(option.value)}
                  alt="Bank Logo"
                  className="aspect-square w-8"
                />
                <p className="font-medium leading-5">{option.label}</p>
              </li>
            ))}
          </ul>
        </div>
      );
    },
    [options, placeholder, setOpen],
  );

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={(
        { field: { onChange, value, onBlur, ref } }, // value: bankEnum;
      ) => (
        <>
          <div
            className={`select relative flex cursor-pointer items-center justify-between pr-1 text-sm font-bold theme-bg-1 theme-border-1`}
            onClick={() => {
              if (!disabled) setOpen(true);
            }}
            ref={ref}
            onBlur={onBlur}
          >
            {value === '' ? (
              <p className="theme-text-6">{placeholder}</p>
            ) : (
              <p>{options.find((option) => option.value === value)?.label}</p>
            )}
            <div>
              <ChevronDown />
            </div>
          </div>
          {isMobile ? (
            <BottomPopup openId={openId}>
              {modalContents(value, onChange, 'w-screen rounded-t-2xl')}
            </BottomPopup>
          ) : (
            <Modal openId={openId}>{modalContents(value, onChange)}</Modal>
          )}
        </>
      )}
    />
  );
};

export default BankSelect;
