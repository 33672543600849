import { FC } from 'react';

import { useRecoilValue } from 'recoil';

import { useFeventListByBugoBrandhook } from '@shared/api/fevent/fevent.hook';
import { feventListByBugoBrandStateSelectorFamily } from '@shared/api/fevent/fevent.selector';
import SearchBar from '@shared/containers/SearchBar/SearchBar';
import { searchTextAtomFamily } from '@shared/containers/SearchBar/state/searchBar.atom';
import { useParams, useSearchParams } from 'react-router-dom';

import { useTitleHook } from '../hooks/useTitleHook';

const SEARCH_TEXT_ID = 'agencyAdminBugoStatMobilePage';

//!Only Used
export enum BugoStatRangeQueryEnum {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Year = 'year',
}

//!Not Used
const AgencyAdminBugoStatMobilePage: FC = () => {
  useTitleHook('부고등록 현황 조회');
  const { bugoBrandId } = useParams();
  const bugoBrandId_ = bugoBrandId ?? 'undefined';

  //* bugoBrand로 가져올 수 있는 부고(fevent)를 가져오는 hook
  useFeventListByBugoBrandhook(bugoBrandId_);

  const feventListState = useRecoilValue(
    feventListByBugoBrandStateSelectorFamily(bugoBrandId_),
  );

  const [searchParams, _] = useSearchParams();
  const range = searchParams.get('range') as BugoStatRangeQueryEnum | null;

  const searchText = useRecoilValue(searchTextAtomFamily(SEARCH_TEXT_ID));

  return (
    <div>
      {/* Search Bar */}
      <div className="p-4">
        <SearchBar
          searchTextAtomId={SEARCH_TEXT_ID}
          placeholder="의전/지도사성함/장례식장/회원번호로 검색"
        />
      </div>
    </div>
  );
};

export default AgencyAdminBugoStatMobilePage;
