import { FC } from 'react';

import { useSetRecoilState } from 'recoil';

import { Button } from '@shared/components/Button';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { useId } from 'react-id-generator';

import WorkerDetailModal from './WorkerDetailModal';

interface IProps {
  workerName: string;
  workerId: string;
  obituaryNotSent?: boolean;
  fontSize?: number;
}

const WorkerDetailModalOpener: FC<IProps> = ({
  workerName,
  workerId,
  obituaryNotSent,
  fontSize,
}: IProps) => {
  const [id] = useId(1, 'workerDetailModalOpener');
  const openId = `modal-${id}`;
  const setOpen = useSetRecoilState(simpleOpenAtomFamily(openId));

  const onClick = () => {
    setOpen(true);
  };

  const textColor = obituaryNotSent ? 'theme-bg-warn' : '';

  return (
    <>
      <Button className={`button-no-border w-full`} onClick={onClick}>
        <div className={`${textColor} absolute rounded-md p-2`}>
          <p className={`text-center text-xs font-bold text-[${fontSize}px]`}>
            {workerName}
          </p>
        </div>
      </Button>
      <WorkerDetailModal openId={openId} workerId={workerId} />
    </>
  );
};

export default WorkerDetailModalOpener;
