import { FC } from 'react';

import { CourtesyKakaoShareContent } from '@service/bugo/utils/courtesy.util';
import { BottomPopup } from '@shared/components/BottomPopup';
import { Icon } from '@shared/components/icons';
import { checkIOS } from '@shared/utils/miscUtil';

interface Props {
  openId: string;
  content: string;
  title: string;
  kakaoContent: CourtesyKakaoShareContent;
}

export const WebCourtesyShareModal: FC<Props> = function WebCourtesyShareModal({
  openId,
  content,
  title,
  kakaoContent,
}: Props) {
  const { ShareMessage, OtherApps, Download } = Icon();
  const smsContent = content.replace(/\n/g, '%0D%0A');
  return (
    <BottomPopup openId={openId}>
      <div className="w-screen rounded-t-2xl px-9 pt-6 pb-12 theme-bg-1">
        <h2 className="bugo-h1 text-center">공유하기</h2>

        <div className="center-box flex gap-6 pt-6">
          {/* <div className="center-box h-[70px] w-[80px]">
            <div className=" flex flex-col items-center justify-center gap-2">
              <div className="center-box wh-12 rounded-lg bg-gray-100">
                <Download className="wh-7 fill-gray-500" />
              </div>
              <p className="text-sm font-bold leading-5 theme-text-1">다운로드</p>
            </div>
          </div> */}
          <a href={checkIOS() ? `sms:&body=${smsContent}` : `sms:?body=${smsContent}`}>
            <div className="center-box h-[70px] w-[80px]">
              <div className="flex flex-col items-center justify-center gap-2">
                <ShareMessage className="h-full w-full" />
                <p className="text-sm font-bold leading-5 theme-text-1">문자</p>
              </div>
            </div>
          </a>
          {/* <div className="center-box h-[70px] w-[80px]">
            <div className="flex flex-col items-center justify-center gap-2">
              <KakaoCourtesyShareButton context={kakaoContent.courtesyContent} />
              <p className="text-sm font-bold theme-text-1">카카오톡</p>
            </div>
          </div> */}
          <button
            onClick={() => {
              let navigatorVar: any;
              // eslint-disable-next-line prefer-const
              navigatorVar = window.navigator;
              if (navigatorVar && navigatorVar.share) {
                navigatorVar
                  .share({
                    title: '답례글\n',
                    text: content,
                  })
                  .then(() => console.log('success'))
                  .catch((error: any) => console.log('error', error));
              } else {
                alert('공유 기능을 지원하지 않는 환경입니다.');
              }
            }}
          >
            <div className="center-box h-[70px] w-[80px]">
              <div className=" flex flex-col items-center justify-center gap-2">
                <OtherApps />
                <p className="text-sm font-bold leading-5 theme-text-1">다른 앱</p>
              </div>
            </div>
          </button>
        </div>
      </div>
    </BottomPopup>
  );
};
