import { FC } from 'react';

import FeventBugoSendPage from '@service/bugo/page/FeventBugoSendPage';
import FeventCourtesySendPage from '@service/bugo/page/FeventCourtesySendPage';
import FeventEditPage from '@service/bugo/page/FeventEditPage';
import FeventOituaryAdminPreviewPage from '@service/bugo/page/FeventObituaryAdminPreviewPage';
import { useFeventHook } from '@shared/api/fevent/fevent.hook';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';

const FeventIdRouter: FC = () => {
  const { feventId } = useParams();
  const feventId_ = feventId ?? 'undefined';
  useFeventHook(feventId_, !feventId);
  return (
    <Routes>
      <Route path="edit" element={<FeventEditPage />} />
      <Route path="obituary-send" element={<FeventBugoSendPage />} />
      <Route path="courtesy-send" element={<FeventCourtesySendPage />} />
      <Route path="obituary-preview" element={<FeventOituaryAdminPreviewPage />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default FeventIdRouter;
