import { createFevent, updateFevent } from '@shared/api/fevent/fevent.controller';
import {
  FeventCreate,
  FeventStatus,
  FeventUpdate,
} from '@shared/api/fevent/fevent.interface';
import { User } from '@shared/api/user/user.interface';
import { notificateError } from '@shared/plugIn/ant-notification/ant-notifiaction';
import { LowerRole, UserRole } from '@shared/types';
import { NotificationInstance } from 'antd/es/notification/interface';
import _ from 'lodash';

import {
  DeceasedInfoFormInputsEnum,
  DeceasedInfoIFormInputs,
} from '../interfaces/feventAddEdit.interface';

export enum FeventFormUseMode {
  Edit = 'edit',
  Send = 'send',
  Add = 'add',
}

//* 생성
export const onFeventDeceasedInfoSubmit = async (
  user: User,
  data: DeceasedInfoIFormInputs,
  setSearchParams: any,
  bugoRole: LowerRole,
  notifiacationInstance: NotificationInstance | null,
) => {
  if (
    _.isNil(data[DeceasedInfoFormInputsEnum.Name]) ||
    data[DeceasedInfoFormInputsEnum.Name].length === 0
  ) {
    notificateError(notifiacationInstance, '고인성함을 작성해주세요');
  }
  const feventCreate: FeventCreate = {
    user: user._id,
    bugoBrand: user.roles.includes(UserRole.BugoAgencyAdmin)
      ? user.bugoAgencyAdminDetail?.bugoBrands[0]?._id ?? 'agencyAdminHasNoBugoBrand'
      : user.bugoBrand,
    registerNumber: data[DeceasedInfoFormInputsEnum.RegisterNumber],
    funeralHomeInfo: data[DeceasedInfoFormInputsEnum.FuneralHomeInfo],
    funeralHomeInfoEmbed: data[DeceasedInfoFormInputsEnum.FuneralHomeInfoEmbed],
    roomInfo: data[DeceasedInfoFormInputsEnum.RoomInfo]
      ? data[DeceasedInfoFormInputsEnum.RoomInfo]
      : undefined,
    roomInfoEmbed: data[DeceasedInfoFormInputsEnum.RoomInfoEmbedName]
      ? {
          name: data[DeceasedInfoFormInputsEnum.RoomInfoEmbedName],
        }
      : undefined,
    members: undefined,
    shopOrders: undefined,
    memberOrderList: [],
    mournerText: data[DeceasedInfoFormInputsEnum.MournerText],
    deceasedInfo: {
      name: data[DeceasedInfoFormInputsEnum.Name],
      age: data[DeceasedInfoFormInputsEnum.Age],
      sex: data[DeceasedInfoFormInputsEnum.Sex],
      cemetery: data[DeceasedInfoFormInputsEnum.Cemetery],
      coffinIn: data[DeceasedInfoFormInputsEnum.CoffinInDate],
      coffinOut: data[DeceasedInfoFormInputsEnum.CoffinOutDate],
      death: data[DeceasedInfoFormInputsEnum.DeathDate],
      enterance: data[DeceasedInfoFormInputsEnum.EnteranceDate],
      bugoTerm: data[DeceasedInfoFormInputsEnum.BugoTerm],
      dpImage: data[DeceasedInfoFormInputsEnum.DpImage],
    },
    status: FeventStatus.Doing,
  };

  const feventCreated = await createFevent(feventCreate, bugoRole);
  setSearchParams({ feventId: feventCreated._id, step: 1 });
};

//* 수정
export const onFeventDeceasedInfoUpdate = async (
  feventId: string,
  data: DeceasedInfoIFormInputs,
  setSearchParams: any,
  bugoRole: LowerRole,
  notifiacationInstance: NotificationInstance | null,
  editMode?: boolean,
) => {
  if (
    _.isNil(data[DeceasedInfoFormInputsEnum.Name]) ||
    data[DeceasedInfoFormInputsEnum.Name].length === 0
  ) {
    notificateError(notifiacationInstance, '고인성함을 작성해주세요');
  }
  const feventUpdate: FeventUpdate = {
    _id: feventId,
    registerNumber: data[DeceasedInfoFormInputsEnum.RegisterNumber],
    funeralHomeInfo: data[DeceasedInfoFormInputsEnum.FuneralHomeInfo],
    funeralHomeInfoEmbed: data[DeceasedInfoFormInputsEnum.FuneralHomeInfoEmbed],
    roomInfo: data[DeceasedInfoFormInputsEnum.RoomInfo]
      ? data[DeceasedInfoFormInputsEnum.RoomInfo]
      : undefined,
    roomInfoEmbed: data[DeceasedInfoFormInputsEnum.RoomInfoEmbedName]
      ? {
          name: data[DeceasedInfoFormInputsEnum.RoomInfoEmbedName],
        }
      : undefined,
    mournerText: data[DeceasedInfoFormInputsEnum.MournerText],
    deceasedInfo: {
      name: data[DeceasedInfoFormInputsEnum.Name],
      age: data[DeceasedInfoFormInputsEnum.Age],
      sex: data[DeceasedInfoFormInputsEnum.Sex],
      cemetery: data[DeceasedInfoFormInputsEnum.Cemetery],
      coffinIn: data[DeceasedInfoFormInputsEnum.CoffinInDate],
      coffinOut: data[DeceasedInfoFormInputsEnum.CoffinOutDate],
      enterance: data[DeceasedInfoFormInputsEnum.EnteranceDate],
      death: data[DeceasedInfoFormInputsEnum.DeathDate],
      bugoTerm: data[DeceasedInfoFormInputsEnum.BugoTerm],
      dpImage: data[DeceasedInfoFormInputsEnum.DpImage],
    },
    status: FeventStatus.Doing,
  };
  await updateFevent(feventUpdate, bugoRole);
  // 부고 생성인 경우
  if (!editMode) {
    setSearchParams({ feventId: feventId, step: 1 });
  }
};
