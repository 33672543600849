//  부고 등록 첫번째 페이지 (고인 정보 등록)
import { FC, useEffect } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import {
  feventDpImageFileNameAtom,
  feventUploadImageAtom,
} from '@service/bugo/containers/FeventDeceasedInfoForm/feventDeceasedInfo.atom';
import {
  onFeventDeceasedInfoSubmit,
  onFeventDeceasedInfoUpdate,
} from '@service/bugo/utils/feventAddEdit.util';
import {
  activeFeventListForWorkerHookUrl,
  feventHookUrl,
} from '@shared/api/fevent/fevent.hook';
import { FeventOne } from '@shared/api/fevent/fevent.interface';
import { FuneralHomeInfoEmbed } from '@shared/api/funeralHomeInfoRequest/funeralHomeInfoRequest.interface';
import { postImage } from '@shared/api/uploadImage';
import { Button } from '@shared/components/Button';
import { FileUploader } from '@shared/components/FileUploader';
import { LoadingModal } from '@shared/components/LoadingModal';
import { TextField } from '@shared/components/TextField';
import Toggle from '@shared/components/Toggle';
import FuneralHomeInfoSearcher from '@shared/containers/FuneralHomeInfoSearcher/FuneralHomeInfoSearcher';
import RoomInfoSearcher from '@shared/containers/RoomInfoSearcher/RoomInfoSearcher';
import Select from '@shared/containers/Select/Select';
import {
  notificateError,
  notificateSuccess,
} from '@shared/plugIn/ant-notification/ant-notifiaction';
import { defaultApiMutationOptions } from '@shared/plugIn/apiMutation';
import { notificationInstanceAtom } from '@shared/state/atom/notification.atom';
import { useAuth } from '@shared/state/hooks/useAuth';
import { Gender } from '@shared/types';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { Link, useSearchParams } from 'react-router-dom';

import {
  DeceasedInfoFormInputsEnum,
  DeceasedInfoIFormInputs,
} from '../../interfaces/feventAddEdit.interface';
import { bugoTermsList, deathCharactor } from '../../variables';
import CemeteryBar from '../CemeteryBar/CemeteryBar';
import { FeventDatePicker } from '../FeventDatePicker/FeventDatePicker';
import { FeventDateTimePicker } from '../FeventDateTimePicker/FeventDateTimePicker';
import { defaultDeceasedInfoInputForm } from './useDeceasedInfoFormInitialize';

interface Props {
  fevent?: FeventOne;
  editMode?: boolean;
  setSelectedTab?: React.Dispatch<React.SetStateAction<number>>;
}

const FeventDeceasedInfoForm: FC<Props> = function FeventDeceasedInfoForm({
  fevent,
  editMode,
  setSelectedTab,
}) {
  const { userProfile, bugoRole, bugoCamelRole, assetRole, isAdmin } = useAuth();
  const cemetoryList = userProfile
    ? userProfile[`${bugoCamelRole()}Detail`]?.cemeteryList ?? []
    : [];
  const feventUploadImage = useRecoilValue(feventUploadImageAtom);
  //* useForm
  const { control, setValue, handleSubmit, register } = useForm<DeceasedInfoIFormInputs>({
    //* 초기값 세팅
    defaultValues: defaultDeceasedInfoInputForm(fevent),
  });
  const setFeventDpFileName = useSetRecoilState(feventDpImageFileNameAtom);
  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);
  const [, setSearchParams] = useSearchParams();

  // loding modal open state
  const queryClient = useQueryClient();
  const { isLoading: processingFevent, mutateAsync } = useMutation(
    defaultApiMutationOptions,
    {
      onSuccess: () => {
        notificateSuccess(
          notifiacationInstance,
          editMode ? '고인정보 수정 완료' : '고인정보 저장 완료',
        );
        if (fevent) {
          queryClient.invalidateQueries(feventHookUrl(fevent._id, bugoRole()));
        }
        queryClient.invalidateQueries(
          activeFeventListForWorkerHookUrl(bugoRole(), isAdmin()),
        );
      },
      onError: () => {
        notificateError(notifiacationInstance, '고인정보 저장 실패');
      },
    },
  );

  // useWatches
  const deathDateValue = useWatch({
    control,
    name: DeceasedInfoFormInputsEnum.DeathDate,
  });
  const enteranceDateValue = useWatch({
    control,
    name: DeceasedInfoFormInputsEnum.EnteranceDate,
  });
  const coffinInDateValue = useWatch({
    control,
    name: DeceasedInfoFormInputsEnum.CoffinInDate,
  });
  const coffinOutDateValue = useWatch({
    control,
    name: DeceasedInfoFormInputsEnum.CoffinOutDate,
  });
  const funeralHomeInfoId = useWatch({
    control,
    name: DeceasedInfoFormInputsEnum.FuneralHomeInfo,
  });
  const funeralHomeInfoName = useWatch({
    control,
    name: DeceasedInfoFormInputsEnum.FuneralHomeInfoName,
  });
  const sex = useWatch({ control, name: DeceasedInfoFormInputsEnum.Sex });
  const roomInfoId = useWatch({ control, name: DeceasedInfoFormInputsEnum.RoomInfo });
  const roomInfoEmebedName = useWatch({
    control,
    name: DeceasedInfoFormInputsEnum.RoomInfoEmbedName,
  });

  useEffect(() => {
    setFeventDpFileName(fevent?.deceasedInfo?.dpImage?.name ?? null);
    //* 처음에만 세팅함
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFuneralHomeSelect = (funeralHomeId: string) => {
    setValue(DeceasedInfoFormInputsEnum.FuneralHomeInfo, funeralHomeId);
  };

  //* funeralHomeEmbed 선택
  const onfuneralHomeInfoEmbedSelect = (
    funeralHomeInfoEmbed: FuneralHomeInfoEmbed,
    funeralHomeInfoId: string,
  ) => {
    setValue(DeceasedInfoFormInputsEnum.FuneralHomeInfo, funeralHomeInfoId);
    setValue(DeceasedInfoFormInputsEnum.FuneralHomeInfoEmbed, funeralHomeInfoEmbed);
  };

  //! 고인정보 등록 및 수정 함수
  const onSubmit: SubmitHandler<DeceasedInfoIFormInputs> = async (data) => {
    try {
      // 업로드 이미지가 있을 경우
      if (feventUploadImage) {
        const dpImage = await postImage(feventUploadImage, assetRole());
        data[DeceasedInfoFormInputsEnum.DpImage] = dpImage._id;
      }

      //* 필수정보 확인
      if (
        !data[DeceasedInfoFormInputsEnum.Name] ||
        !(
          data[DeceasedInfoFormInputsEnum.FuneralHomeInfo] ||
          data[DeceasedInfoFormInputsEnum.FuneralHomeInfoEmbed]
        ) ||
        !(
          data[DeceasedInfoFormInputsEnum.RoomInfo] ||
          data[DeceasedInfoFormInputsEnum.RoomInfoEmbedName]
        )
      ) {
        alert(`필수정보를 모두 입력해주세요`);
        return;
      }
      //* bugo role check 유저 확인
      if (userProfile?._id) {
        // * fevent 있을 경우 update
        if (fevent) {
          await mutateAsync(
            onFeventDeceasedInfoUpdate(
              fevent._id,
              data,
              setSearchParams,
              bugoRole(),
              notifiacationInstance,
              editMode,
            ),
          );
          // 수정 모드 일 경우에 대해서 상주 수정으로 가짐
          if (editMode && setSelectedTab) {
            setSelectedTab(1);
            setSearchParams({ step: '1' });
          }
        }
        // fevent 없을 경우
        else {
          if (editMode) {
            notificateError(
              notifiacationInstance,
              '해당 부고의 장례가 존재하지 않습니다!',
            );
          }
          // fevent 생성
          else {
            await mutateAsync(
              onFeventDeceasedInfoSubmit(
                userProfile,
                data,
                setSearchParams,
                bugoRole(),
                notifiacationInstance,
              ),
            );
          }
        }
      }
    } catch (err: any) {
      notificateError(notifiacationInstance, err?.message);
      console.error('error', err);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <p className="pb-4 text-sm font-medium text-warn">
          ※빨간색 테두리 영역은 필수정보입니다
        </p>
        {/* 1st main input area */}
        <div>
          <div className="grid grid-cols-2 gap-x-2 gap-y-3">
            {/* 고인성함 */}
            <TextField
              type="text"
              autoComplete="off"
              className="w-full pl-9"
              placeholder="고인 성함"
              required={true}
              {...register(DeceasedInfoFormInputsEnum.Name, { required: true })}
            >
              <p className="batang absolute left-4 top-1/2 -translate-y-1/2 font-bold">
                {deathCharactor}
              </p>
            </TextField>
            {/* 성별 */}
            <div className="bg-white">
              <Toggle
                toggle={sex !== Gender.Femail}
                toggleValue={{
                  on: '남성',
                  off: '여성',
                }}
                onChagne={(toggle) => {
                  setValue(
                    DeceasedInfoFormInputsEnum.Sex,
                    toggle ? Gender.Male : Gender.Femail,
                  );
                }}
              ></Toggle>
            </div>
            {/* 고인나이 */}
            <TextField
              type="number"
              className="w-full"
              placeholder="고인 나이"
              pattern="\d*"
              autoComplete="off"
              maxLength={3}
              {...register(DeceasedInfoFormInputsEnum.Age)}
            >
              <p className="absolute right-4 top-1/2 -translate-y-1/2 font-bold">세</p>
            </TextField>
            {/* 부고용어 select */}
            <Select
              placeholder={'별세'}
              optionList={bugoTermsList}
              control={control}
              name={DeceasedInfoFormInputsEnum.BugoTerm}
            />
            {/* 영정사진 업로드 */}
            <div className="col-span-2">
              <FileUploader placeholder="영정사진(선택사항)" accept="image/*" />
            </div>
            <FeventDatePicker
              placeholder={`별세일`}
              fDate={deathDateValue}
              name={DeceasedInfoFormInputsEnum.DeathDate}
              setValue={setValue}
              pickerId={`feventDatePicker-1`}
            />
            <FeventDateTimePicker
              className="col-span-2"
              placeholder="입실"
              fDate={enteranceDateValue}
              setValue={setValue}
              name={DeceasedInfoFormInputsEnum.EnteranceDate}
            />
            <FeventDateTimePicker
              className="col-span-2"
              placeholder="입관"
              fDate={coffinInDateValue}
              setValue={setValue}
              name={DeceasedInfoFormInputsEnum.CoffinInDate}
            />
            <FeventDateTimePicker
              className="col-span-2"
              placeholder="발인"
              fDate={coffinOutDateValue}
              setValue={setValue}
              name={DeceasedInfoFormInputsEnum.CoffinOutDate}
            />
            <div className="col-span-2 w-full">
              <CemeteryBar
                placeholder="장지"
                optionList={cemetoryList}
                control={control}
                name={DeceasedInfoFormInputsEnum.Cemetery}
                setValue={setValue}
                className="col-span-2 text-sm"
              />
            </div>
            <div className="col-span-2 text-sm">
              <textarea
                placeholder="상주 말씀"
                className="input-box min-h-16 w-full p-2 font-medium theme-bg-1 theme-border-1 placeholder:theme-text-6"
                {...register(DeceasedInfoFormInputsEnum.MournerText)}
              ></textarea>
            </div>
          </div>
        </div>
        {/* 2nd main input area */}
        <div className="space-y-3 pt-6 font-bold">
          <h4>장례식장 정보</h4>
          <FuneralHomeInfoSearcher
            value={funeralHomeInfoName}
            onValueChange={onFuneralHomeSelect}
            onEmbedSelect={onfuneralHomeInfoEmbedSelect}
          />
          <RoomInfoSearcher
            funeralHomeInfoId={funeralHomeInfoId}
            roomInfoId={roomInfoId}
            roomInfoEmebedName={roomInfoEmebedName}
            setValue={setValue}
            control={control}
            register={register}
          />
        </div>
        <div className="space-y-4">
          <Button
            type="submit"
            disabled={processingFevent}
            className="button-rectangle mt-8 text-white theme-bg-main"
          >
            다음
          </Button>
          {editMode && (
            <div>
              <Link to="/fevent/manage">
                <Button className="button-rectangle text-white theme-bg-12">
                  돌아가기
                </Button>
              </Link>
            </div>
          )}
        </div>
      </form>
      <LoadingModal
        open={processingFevent}
        title={editMode ? '장례 수정중' : '장례 등록중'}
      />
    </>
  );
};

export default FeventDeceasedInfoForm;
