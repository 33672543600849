//*Seller가 해당 fevent의 상품 구매하도록

import { FC, useMemo, useState } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { getEstimateDeliveredAt } from '@service/store/utils/getEstimateDeliveredAt';
import { Bugo } from '@shared/api/bugo/bugo.interface';
import { FeventByShop } from '@shared/api/fevent/fevent.interface';
import { Image } from '@shared/api/media/media.interface';
import { Member } from '@shared/api/member/member.interface';
import { ShopItem } from '@shared/api/shopItem/shopItem.interface';
import { ShopItemInfo } from '@shared/api/shopItemInfo/shopItemInfo.interface';
import { createWaitingOrder } from '@shared/api/shopOrder/shopOrder.controller';
import { ShopOrderType } from '@shared/api/shopOrder/shopOrder.interface';
import { createOrGetStoreGuest } from '@shared/api/user/user.controller';
import { Loading } from '@shared/components/Loading';
import Modal from '@shared/components/Modal';
import { DeathCharacter } from '@shared/miscValues';
import { notificateSuccess } from '@shared/plugIn/ant-notification/ant-notifiaction';
import { defaultApiMutationOptions } from '@shared/plugIn/apiMutation';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { notificationInstanceAtom } from '@shared/state/atom/notification.atom';
import { useAuth } from '@shared/state/hooks/useAuth';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';

import { CreateCallOrderForm } from './CallOrderCreateForm';
import { SelectMemberForm } from './SelectMemberForm';
import { SelectShopItemForm } from './SelectShopItemForm';

//* 전화주문으로 shopOrder 생성하기 위한 모달
interface Props {
  fevent: FeventByShop | null;
  openId: string;
}
export enum SellerCallOrderCreateModalStepEnum {
  Member = 'member',
  Item = 'item',
  Detail = 'detail',
}

export const SellerShopOrderCreateModal: FC<Props> = function SellerShopOrderCreateModal({
  fevent,
  openId,
}: Props) {
  //!step 별로 이동
  // 1. 상주 선택
  // 2. 상품 선택
  // 3. 문구 입력
  // 4. 문자 발송
  const { sellerRole } = useAuth();
  const { register, control, handleSubmit, setValue } = useForm();

  const { shopId } = useParams();
  const setOpen = useSetRecoilState(simpleOpenAtomFamily(openId));
  const [step, setStep] = useState<SellerCallOrderCreateModalStepEnum>(
    SellerCallOrderCreateModalStepEnum.Member,
  );
  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);
  const { isLoading: creatingCallOrder, mutate } = useMutation(
    defaultApiMutationOptions,
    {
      onSuccess: () => {
        notificateSuccess(notifiacationInstance, '주문생성 및 입금안내 발송완료');
      },
    },
  );

  const stepContent = useMemo(() => {
    //* member가 선택됨
    const onSelectMember = (member: Member<string, Bugo>) => {
      setValue('deliveryDetail.receiverName', member.fullName + ' 상주님');
      setValue(
        'deliveryDetail.receiverAddress',
        fevent?.funeralHomeInfoEmbed?.address ?? fevent?.funeralHomeInfo?.address ?? '',
      );
      setValue('deliveryDetail.receiverPhoneNumber', member.phoneNumber);
      setValue('receiverPostalCode', fevent?.funeralHomeInfo.postalCode);
      setValue('bugo', member.bugo?._id);
      setStep(SellerCallOrderCreateModalStepEnum.Item);
    };

    //* shopItem이 선택됨
    const onSelectShopItem = (
      shopItem: ShopItem<ShopItemInfo<string, Image>, string, string, Image>,
    ) => {
      setValue('orderDetail.shopItem', shopItem._id);
      setValue('orderDetail.quantity', 1);
      setValue('paymentDetail.paymentByCash', shopItem.priceRetail);
      setValue('paymentDetail.paymentByPoint', 0);
      setStep(SellerCallOrderCreateModalStepEnum.Detail);
    };

    if (fevent) {
      switch (step) {
        case SellerCallOrderCreateModalStepEnum.Member:
          return <SelectMemberForm fevent={fevent} onSelectMember={onSelectMember} />;
        case SellerCallOrderCreateModalStepEnum.Item:
          return (
            <SelectShopItemForm fevent={fevent} onSelectShopItem={onSelectShopItem} />
          );
        case SellerCallOrderCreateModalStepEnum.Detail:
          return (
            <CreateCallOrderForm
              register={register}
              control={control}
              creatingCallOrder={creatingCallOrder}
            />
          );
      }
    } else {
      return (
        <div className="center-box">
          <Loading></Loading>
        </div>
      );
    }
  }, [control, creatingCallOrder, fevent, register, setValue, step]);

  const innerContent = useMemo(() => {
    const onSubmit = async (data: any) => {
      const user = await createOrGetStoreGuest(
        {
          name: data.deliveryDetail.senderName,
          phoneNumber: data.deliveryDetail.senderPhoneNumber,
          password: data.senderPassword,
        },
        sellerRole(),
      );

      const shopOrderCreate = {
        ...data,
        user: user._id,
        shop: shopId,
        type: ShopOrderType.Bugo,
        deliveryDetail: {
          ...data.deliveryDetail,
          estimatedDeliveredAt: getEstimateDeliveredAt(),
          senderAddress: '',
        },
      };

      await createWaitingOrder(shopOrderCreate, sellerRole());

      setOpen(false);
    };

    if (fevent) {
      return (
        <form
          className="text-sm"
          onSubmit={(e) => {
            mutate(handleSubmit(onSubmit)(e));
          }}
        >
          {/* header */}
          <div className="-mx-6 -mt-6 rounded-t-lg px-6 py-4 theme-text-1 theme-bg-5">
            <h4 className="text-center text-18 font-bold">
              <p>전화주문생성</p>
            </h4>
            <div className="flex items-center justify-end gap-1 font-medium">
              <p>
                {DeathCharacter}
                {fevent.deceasedInfo.name}님
              </p>
              <p>{fevent.funeralHomeInfoEmbed?.name ?? fevent.funeralHomeInfo?.name}</p>
              <p>{fevent.roomInfoEmbed?.name ?? fevent.roomInfo?.name}</p>
            </div>
          </div>
          <div className="py-4">
            <div className="">{stepContent}</div>
          </div>
        </form>
      );
    } else {
      return (
        <div>
          <Loading></Loading>
        </div>
      );
    }
  }, [fevent, handleSubmit, mutate, sellerRole, setOpen, shopId, stepContent]);

  return (
    <Modal
      openId={openId}
      onClose={() => {
        setStep(SellerCallOrderCreateModalStepEnum.Member);
      }}
    >
      {innerContent}
    </Modal>
  );
};
