//* 조문객용 부고문자 컴포넌트
import { FC, useMemo } from 'react';

import { useSetRecoilState } from 'recoil';

import {
  bugoKakaoShareContent,
  bugoMessageContentString,
} from '@service/bugo/utils/bugoMessage.util';
import { BugoData } from '@shared/api/bugo/bugo.interface';
import { Button } from '@shared/components/Button';
import { WebShareModal } from '@shared/containers/WebShareModal/WebShareModal';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { fileBucketUrl } from '@shared/utils/fileUtils';
import { Link } from 'react-router-dom';
import bottomBanner from 'src/assets/banner/bottomBanner.jpg';
import topLogo from 'src/assets/logo/topLogo.png';

import { FlowerPromotionBox } from '../FlowerPromotion/FlowerPromoitonBox';
import { MemberMoney } from './MemberMoney';
import { ObituaryFeventGrid } from './ObituaryFeventGrid';
import { ObituaryFuneralHomeInfo } from './ObituaryFuneralHomeInfo';
import { ObituaryHeader } from './ObituaryHeader';
import { ObituaryMemberGrid } from './ObituaryMemberGrid';

interface Props {
  bugo: BugoData;
  forMember?: boolean;
}

const Obituary: FC<Props> = function Obituary({ bugo, forMember }: Props) {
  const openId = `bugo-${bugo._id}`;

  const headerImage = bugo.bugoBrand?.bugoAgency?.headerImage.url
    ? fileBucketUrl(bugo.bugoBrand?.bugoAgency?.headerImage.url, 'w400')
    : topLogo;

  const footerImage = bugo.bugoBrand?.bugoAgency?.footerImage?.url
    ? fileBucketUrl(bugo.bugoBrand?.bugoAgency?.footerImage?.url, 'w1080')
    : bottomBanner;

  const setShareModalOpen = useSetRecoilState(simpleOpenAtomFamily(openId));
  const bottomButton = useMemo(() => {
    return (
      <div className="fixed bottom-0 z-20 grid h-14 w-full max-w-inherit grid-cols-2 bg-zinc-800">
        <div className="theme-bg-main">
          <Link
            className="center-box h-full w-full gap-1 text-base font-bold"
            to={`/flower-store/?bugoId=${bugo._id}`}
          >
            <span className="text-white">근조화환</span>
          </Link>
        </div>
        <Button
          className="button-rectangle h-full text-base text-white theme-bg-13"
          onClick={() => {
            setShareModalOpen(true);
          }}
        >
          <span>공유</span>
        </Button>
      </div>
    );
  }, [bugo._id, setShareModalOpen]);

  const bugoBrand = bugo.bugoBrand;

  return (
    <div className="max-w-inherit pb-14">
      {/* 0th 로고 */}
      <div className="center-box h-[60px]">
        <img src={headerImage} className="h-9"></img>
      </div>
      {/* 1st header */}
      <ObituaryHeader bugo={bugo} />
      {/* 2nd 상주, 장례 정보 */}
      <div className="space-y-6 pt-6">
        <div className="grid grid-cols-auto-1fr gap-x-3 gap-y-3 whitespace-pre-line break-keep px-6 text-16 leading-6">
          <ObituaryMemberGrid bugo={bugo} />
          {/* 실제 사용하고 있는 blank div*/}
          <div></div>
          <div></div>
          <ObituaryFeventGrid bugo={bugo} />
        </div>
        {/* 3rd 장례식장 정보 */}
        <ObituaryFuneralHomeInfo bugo={bugo} />
        {/* 4th 부의금 정보 */}
        <div className="px-4">
          <MemberMoney
            cashAccount={bugo.member.cashAccount}
            forMember={forMember}
            cashAccountEditOpenId={`memberObituary-cashAccountOpen-${bugo._id}`}
          />
        </div>
        {/* 5th 화환 정보 */}
        <div className="px-4">
          <FlowerPromotionBox bugo={bugo} />
        </div>

        <div className="w-full">
          {bugoBrand?.bugoAgency?.homepageUrl ? (
            <a href={bugoBrand?.bugoAgency?.homepageUrl}>
              <img src={footerImage} className="w-full"></img>
            </a>
          ) : (
            <img src={footerImage} className="w-full"></img>
          )}
        </div>
      </div>
      <WebShareModal
        openId={openId}
        content={bugoMessageContentString(bugo)}
        title={'- 부고'}
        kakaoContent={bugoKakaoShareContent(bugo)}
        bugoBrand={bugo.bugoBrand}
      />
      {bottomButton}
    </div>
  );
};

export default Obituary;
