import { FC, forwardRef, InputHTMLAttributes, useEffect, useMemo, useState } from 'react';

import { useRecoilState, useRecoilValue } from 'recoil';

import { DeliveryContentCard } from '@service/seller/components/DeliveryContentCard';
import { updateShopOrder } from '@shared/api/shopOrder/shopOrder.controller';
import { shopOrderListForSellerByShopUrl } from '@shared/api/shopOrder/shopOrder.hook';
import { ShopOrderForSeller } from '@shared/api/shopOrder/shopOrder.interface';
import { postImage } from '@shared/api/uploadImage';
import {
  notificateError,
  notificateSuccess,
} from '@shared/plugIn/ant-notification/ant-notifiaction';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { uploadFileAtomFamily } from '@shared/state/atom/file.atom';
import { notificationInstanceAtom } from '@shared/state/atom/notification.atom';
import { useAuth } from '@shared/state/hooks/useAuth';
import {
  fileBucketUrl,
  singleImageSelectHandlerWithPreview,
} from '@shared/utils/fileUtils';
import { Image } from 'antd';
import { useId } from 'react-id-generator';
import { useQueryClient } from 'react-query';

import { Button } from './Button';
import { Icon } from './icons';
import { Label } from './Label';
import Modal from './Modal';

//* DeliveredImage Uploader
interface DeliveredImageUploaderProps {
  order: ShopOrderForSeller;
  openId: string;
}
const DeliveredImageUploader: FC<DeliveredImageUploaderProps> = ({
  order,
  openId,
}: DeliveredImageUploaderProps) => {
  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);
  const { UploadImage, X } = Icon();
  const [open, setOpen] = useRecoilState(simpleOpenAtomFamily(openId));
  const [file, setFile] = useState<any>(null);
  const [preview, setPreview] = useState<any>();
  const { assetRole, sellerRole } = useAuth();
  const queryClient = useQueryClient();
  const previewRender = useMemo(() => {
    if (preview) {
      return (
        <div className="">
          <img src={preview} alt="미리보기" className="object-cover"></img>
        </div>
      );
    } else if (order.deliveryDetail.deliveredImage?.url) {
      return (
        <div>
          <img
            src={fileBucketUrl(order.deliveryDetail.deliveredImage?.url, 'w200')}
            alt="기존파일"
            className="object-cover"
          />
        </div>
      );
    } else {
      return (
        <div className="center-box space-x-4">
          <UploadImage className="" />
          <p>배송 완료 사진을 업로드 해주세요.</p>
        </div>
      );
    }
  }, [UploadImage, order.deliveryDetail.deliveredImage?.url, preview]);

  const onSubmit_ = async () => {
    if (file) {
      try {
        const deliveredImage = await postImage(file, assetRole());
        const shopOrderImageUpdate = {
          _id: order._id,
          'deliveryDetail.deliveredImage': deliveredImage._id,
        };
        console.log('test', shopOrderImageUpdate);
        await updateShopOrder(shopOrderImageUpdate, sellerRole());
        notificateSuccess(notifiacationInstance, '업로드 성공');
        await queryClient.invalidateQueries(
          shopOrderListForSellerByShopUrl(order.shop, sellerRole()),
        );
        setOpen(false);
      } catch (err: any) {
        notificateError(notifiacationInstance, '실패');
        console.log('deliveredImage upload failed' + err?.response?.data);
      }
    } else {
      alert('파일을 선택 후 업로드 해주세요');
    }
  };

  return (
    <Modal openId={openId}>
      <form>
        <div className="space-y-4">
          <Label>배송 완료 사진 업로드</Label>
          <DeliveryContentCard shopOrder={order} short={true} />
          <div
            className={`relative w-full items-center rounded-md border border-gray-200 p-4 text-gray-400`}
          >
            <input
              type="file"
              accept="image/*"
              onChange={(e: any) => {
                singleImageSelectHandlerWithPreview(e, setFile, setPreview);
              }}
              className="absolute h-full w-full cursor-pointer rounded-xl opacity-0"
            ></input>
            {(file || preview) && (
              <div className="absolute right-2 top-2">
                <Button
                  className="icon-button bg-white"
                  type="button"
                  onClick={() => {
                    setFile(null);
                    setPreview(null);
                  }}
                >
                  <X className="wh-5 fill-warn" />
                </Button>
              </div>
            )}
            {previewRender}
          </div>
          <div className="flex justify-end">
            <Button
              type="button"
              text="업로드"
              className="filled-gray-800"
              onClick={onSubmit_}
            />
          </div>
        </div>
      </form>
    </Modal>
  );
};

interface SingleImageUploaderProps extends InputHTMLAttributes<HTMLInputElement> {
  uploadFileAtomId: string;
  formerImageUrl?: string;
}

const SingleImageUploader = forwardRef<HTMLInputElement, SingleImageUploaderProps>(
  ({ uploadFileAtomId, formerImageUrl, placeholder, className, ...props }, ref) => {
    const { X } = Icon();
    const [id] = useId(1, 'single-image-uploader');

    const [uploadImageFile, setUploadImageFile] = useRecoilState(
      uploadFileAtomFamily(uploadFileAtomId),
    );
    const [preview, setPreview] = useState<any>();

    useEffect(() => {
      return setUploadImageFile(null);
    }, [setUploadImageFile]);

    return (
      <div>
        <label
          htmlFor={id}
          className={`${className} input-box relative flex items-center justify-between pr-[1px] theme-bg-1 theme-border-1`}
        >
          <input
            {...props}
            ref={ref}
            type="file"
            accept="image/*"
            id={id}
            onChange={(e) => {
              singleImageSelectHandlerWithPreview(e, setUploadImageFile, setPreview);
            }}
            className={`absolute left-0 h-full w-full cursor-pointer opacity-0`}
          />
          <div className="w-full flex-1">
            {uploadImageFile ? (
              <p className="block max-w-[250px] overflow-x-hidden text-ellipsis whitespace-nowrap sm:max-w-none">
                {uploadImageFile.name}
              </p>
            ) : (
              <p className="theme-text-6">{placeholder ?? '업로드(파일)'}</p>
            )}
          </div>
          <div className="flex h-full items-center py-[1px]">
            {uploadImageFile ? (
              <Button
                className="button-no-border relative px-1"
                onClick={(event) => {
                  event.preventDefault();
                  setUploadImageFile(null);
                  setPreview(null);
                }}
              >
                <X />
              </Button>
            ) : (
              <Button className="button-rectangle h-full px-[18px] theme-text-4 theme-bg-13">
                업로드
              </Button>
            )}
          </div>
        </label>
        <div className="center-box w-full pt-2">
          <div className="w-1/2">
            <Image src={preview ?? formerImageUrl}></Image>
          </div>
        </div>
      </div>
    );
  },
);

export { DeliveredImageUploader, SingleImageUploader };
