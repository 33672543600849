import { atom, atomFamily } from 'recoil';

import { QueryStatus } from 'react-query';

import { User, WorkerUser } from './user.interface';

export const userStatusAtom = atom<QueryStatus>({
  key: 'userStatusAtom',
  default: 'idle',
});

export const userDataAtom = atom<User | null>({
  key: 'userDataAtom',
  default: null,
});

export const workerUserListByBugoBrandStatusAtomFamily = atomFamily<QueryStatus, string>({
  key: 'workerUserListByBugoBrandStatusAtomFamily',
  default: 'idle',
});

export const workerUserListByBugoBrandDataAtomFamily = atomFamily<
  WorkerUser[] | null,
  string
>({
  key: 'workerUserListByBugoBrandDataAtomFamily',
  default: null,
});

export const workerUserStatusAtomFamily = atomFamily<QueryStatus, string>({
  key: 'workerUserStatusAtomFamily',
  default: 'idle',
});

export const workerUserDataAtomFamily = atomFamily<WorkerUser | null, string>({
  key: 'workerUserDataAtomFamily',
  default: null,
});
