import { FC } from 'react';

import { useRecoilValue } from 'recoil';

import { Button } from '@shared/components/Button';
import { Icon } from '@shared/components/icons';
import { redirectErrorPath } from '@shared/state/atom/redirectPathAtom.atom';
import { Link } from 'react-router-dom';

const ErrorPage: FC = () => {
  const redirectPath = useRecoilValue(redirectErrorPath);
  const { Error } = Icon();

  return (
    <div className="flex h-screen flex-col items-center justify-between bg-gray-800 px-6 pb-10 text-white">
      {/* 배치를 위한 empty div */}
      <div></div>
      {/* 오류 문구 */}
      <div className="flex flex-col items-center bg-transparent">
        {/* 체크 | 느낌표 아이콘 */}
        <Error />
        <span className="mt-5 text-base font-bold leading-[22px]">
          일시적인 오류입니다.
        </span>
        {/* 주문 정보 */}
        <p className="mt-1.5 text-center text-sm text-gray-100">{`잠시 후에 다시 시도해주세요 :)`}</p>
      </div>
      {/* redirect button */}
      <Link to={redirectPath} className="w-full">
        <Button className="button-rectangle theme-bg-main">돌아가기</Button>
      </Link>
    </div>
  );
};

export default ErrorPage;
