import { FC } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { deleteFevent } from '@shared/api/fevent/fevent.controller';
import { activeFeventListForWorkerHookUrl } from '@shared/api/fevent/fevent.hook';
import { Fevent, FeventStatus } from '@shared/api/fevent/fevent.interface';
import { getFeventStatus } from '@shared/api/fevent/fevent.util';
import { FuneralHomeInfo } from '@shared/api/funeralHomeInfo/funeralHomeInfo.interface';
import { Member } from '@shared/api/member/member.interface';
import { User } from '@shared/api/user/user.interface';
import DismissModal from '@shared/components/DismissModal';
import { RoomInfo } from '@shared/interfaces/roomInfo.interface';
import {
  notificateError,
  notificateSuccess,
} from '@shared/plugIn/ant-notification/ant-notifiaction';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { notificationInstanceAtom } from '@shared/state/atom/notification.atom';
import { useAuth } from '@shared/state/hooks/useAuth';
import { formatFeventDateString } from '@shared/utils/formatDate';
import { useQueryClient } from 'react-query';

import { FeventManageModal } from '../containers/FeventManageModal/FeventManageModal';

interface Props {
  fevent: Omit<
    Fevent<User, FuneralHomeInfo, RoomInfo, undefined, undefined, Member[]>,
    'shopOrders'
  >; //Fevent
}

/**
 * @pros
 * @fevent: 해당 부고 객체
 * @returns 부고 카드
 */
const getStatusString = (status: FeventStatus) => {
  switch (status) {
    case FeventStatus.Todo:
      return '입실예정';
    case FeventStatus.Doing:
      return '진행중';
    case FeventStatus.Done:
      return '발인완료';
    default:
      return '입실예정';
  }
};

const tagBgColorGenerator = (status: FeventStatus) => {
  switch (status) {
    case FeventStatus.Todo:
      return 'theme-bg-14';
    case FeventStatus.Doing:
      return 'theme-bg-main';
    case FeventStatus.Done:
      return 'theme-bg-12';
    default:
      return 'bg-gray-300';
  }
};

export const BugoCard: FC<Props> = function BugoCard({ fevent }: Props) {
  const openId = `fevent-manage-modal-${fevent._id}`;
  const setOpen = useSetRecoilState(simpleOpenAtomFamily(openId));

  const { isBugoAgencyAdmin, isAdmin, isBugoAgencyWorker, bugoRole } = useAuth();

  const deleteOpenId = `fevent-card-delete-${fevent._id}`;
  const setDeleteOpen = useSetRecoilState(simpleOpenAtomFamily(deleteOpenId));

  const isPrivacyDeleted = fevent.meta.isDeleted;

  const queryClient = useQueryClient();
  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);

  const deleteFeventFunc = async () => {
    try {
      await deleteFevent(fevent._id, bugoRole());
      queryClient.invalidateQueries(
        activeFeventListForWorkerHookUrl(bugoRole(), isAdmin()),
      );
      notificateSuccess(notifiacationInstance, '삭제 완료');
    } catch (error) {
      notificateError(notifiacationInstance, '에러발생');
      console.error(error);
    }
  };

  const funeralHomeInfo = fevent.funeralHomeInfoEmbed ?? fevent.funeralHomeInfo;

  return (
    <>
      <div
        className={`space-y-2 rounded-2xl p-2 shadow-smd theme-text-1 theme-bg-1 ${
          isPrivacyDeleted ? 'opacity-40' : ''
        }`}
        onClick={() => {
          if (isPrivacyDeleted) {
            alert('해당 부고는 파기되었습니다.');
          } else {
            setOpen(true);
          }
        }}
      >
        {/* 1st row */}
        <div className="flex items-center justify-between">
          <div className="flex gap-4 pl-1">
            {/* 고인태그 */}
            <div className="bugo-tag w-fit text-white theme-bg-14">
              故 {fevent.deceasedInfo.name}
            </div>
            {/* 상주태그 */}
            {fevent.memberOrderList[0]?.fullName && (
              <div className="bugo-tag w-fit text-black theme-bg-5">
                상주&nbsp;
                <span className="font-bold">{fevent.memberOrderList[0]?.fullName}</span>
              </div>
            )}
          </div>
          {/* 미리보기버튼 */}
          {(isBugoAgencyAdmin() || isAdmin()) && (
            <div className="bugo-tag w-fit rounded-none bg-gray-100 text-black">
              지도사&nbsp;
              <span className="font-bold">{fevent.user?.info?.name}</span>
            </div>
          )}
        </div>
        {/* 2nd row: funeralHome info */} {/* 3rd row: bugo status */}
        <div
          className="grid items-center gap-y-1 px-4 pb-1 text-12"
          style={{
            gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 0.4fr)',
          }}
        >
          {/* 2nd row */}
          <>
            {/* 장례식장 */}
            <div>
              <p className="leading-5">{funeralHomeInfo?.name}</p>
            </div>
            {/* 빈소 호실 */}
            <div className="">
              <p className="break-keep leading-5">
                {fevent.roomInfo?.name ?? fevent.roomInfoEmbed?.name}
              </p>
            </div>
          </>
          {/* 3rd row */}
          <>
            {/* 발인 시각 */}
            <div>
              <p className="break-keep leading-5">
                발인 {formatFeventDateString(fevent.deceasedInfo.coffinOut)}
              </p>
            </div>
            {/* 상태 */}
            <div
              className={`center-box ${tagBgColorGenerator(
                getFeventStatus(fevent.status, fevent.deceasedInfo.enterance),
              )}  py-1.5 px-2 text-white`}
            >
              <p className="keep-all whitespace-nowrap text-[12px] font-bold">
                {getStatusString(
                  getFeventStatus(fevent.status, fevent.deceasedInfo.enterance),
                )}
              </p>
            </div>
          </>
        </div>
      </div>
      <FeventManageModal
        title={`故 ${fevent.deceasedInfo.name}님 - ${
          fevent?.funeralHomeInfo?.name ?? ''
        }`}
        feventId={fevent._id}
      />
      <DismissModal
        openId={deleteOpenId}
        onClick={deleteFeventFunc}
        buttonTitle={'삭제'}
        title={'장례(부고) 삭제'}
        subtitle="해당 장례를 삭제합니다. 삭제된 장례는 복원이 불가능합니다."
      ></DismissModal>
    </>
  );
};
