import { FC } from 'react';

import { makeOptionList } from '@shared/containers/Select/Select';
import { IAutoCompleteProps } from '@shared/interfaces/autoComplete.interface';
import { Controller } from 'react-hook-form';
import { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { Icon } from '../icons';

const PCAutoComplete: FC<IAutoCompleteProps> = ({
  placeholder,
  optionList,
  control,
  name,
  className,
  optionClassName,
  defaultValue = '',
}: IAutoCompleteProps) => {
  const { ChevronDown, X } = Icon();
  const options = makeOptionList(optionList);

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({ field: { onChange, value, onBlur, ref } }) => (
        <CreatableSelect
          ref={ref}
          name={placeholder}
          options={options}
          value={
            value
              ? options.find((option) => option.value === value) ?? {
                  label: value,
                  value: value,
                }
              : null
          }
          isClearable
          placeholder={placeholder}
          onChange={(option) => onChange(option ? option.value : '')}
          onBlur={onBlur}
          menuPlacement="auto"
          formatCreateLabel={(inputText) => `"${inputText}" 입력`}
          unstyled
          className={className}
          classNames={{
            control: (state) => {
              return `${className} w-full theme-bg-1 theme-border-1 font-bold pl-4 pr-[3px] h-11 border hover:cursor-pointer`;
            },
            option: (state) => {
              const value_ = state.data.value;
              return `theme-border-1 ${
                state.isFocused && value_ !== value ? 'theme-bg-5 text-black' : ''
              } ${
                value_ === value
                  ? 'font-bold theme-bg-main text-white hover:theme-bg-main hover:text-white'
                  : 'theme-bg-1'
              } ${optionClassName} option-default`;
            },
            menu: () => {
              return `border theme-border-1 my-2 p-0 shadow-lg`;
            },
            placeholder: () => {
              return `theme-text-6`;
            },
          }}
          styles={{
            control: (styles) => ({
              ...styles,
              fontSize: 14,
              lineHeight: '20px',
            }),
            option: (styles) => ({
              ...styles,
              fontSize: 13,
              lineHeight: '20px',
            }),
          }}
          components={{
            DropdownIndicator: (props) =>
              props.hasValue ? null : (
                <components.DropdownIndicator {...props}>
                  <ChevronDown className="fill-gray-500 stroke-none" />
                </components.DropdownIndicator>
              ),
            ClearIndicator: (props) => (
              <components.ClearIndicator {...props}>
                <X className="fill-gray-800 text-gray-800" />
              </components.ClearIndicator>
            ),
          }}
        />
      )}
    />
  );
};

export default PCAutoComplete;
