import { FC } from 'react';

import { useRecoilValue } from 'recoil';

import { shopOrderForStoreStateSelectorFamily } from '@shared/api/shopOrder/shopOrder.selector';
import { Button } from '@shared/components/Button';
import { Icon } from '@shared/components/icons';
import { fileBucketUrl } from '@shared/utils/fileUtils';
import { formatDate } from '@shared/utils/formatDate';
import { formatPrice } from '@shared/utils/formatPrice';
import { Link, useParams, useSearchParams } from 'react-router-dom';

import { useStoreTitleHook } from '../hooks/useStoreTitleHook';

const OrderPaymentResultPage: FC = () => {
  const { BigCheck, Error } = Icon();

  const { orderId } = useParams();
  const [searchParams] = useSearchParams();
  const result = searchParams.get('success');
  const bugoId = searchParams.get('bugoId');

  useStoreTitleHook(result === 'true' ? '주문 완료' : '주문 실패', ' ');
  const isSuccessed = result === 'true';

  const ShopOrderForStoreState = useRecoilValue(
    shopOrderForStoreStateSelectorFamily(orderId ?? 'undefined'),
  );
  const data = ShopOrderForStoreState.data;

  return (
    <div>
      {ShopOrderForStoreState.status === 'success' && data && (
        <>
          {/* 주문 정보 */}
          <div className="flex flex-col items-center bg-transparent">
            {/* 체크 | 느낌표 아이콘 */}
            {/* TODO: Theme에 맞는 아이콘으로 변경해야함. */}
            {isSuccessed ? (
              <BigCheck className="mt-9 text-4xl font-bold" />
            ) : (
              <Error className="mt-9 text-4xl font-bold" />
            )}
            <span className="mt-7 text-sm font-bold">
              주문이 {isSuccessed ? '완료' : '실패'}되었습니다.
            </span>
            {/* 주문 정보 */}
            <p className="mt-2 flex flex-col justify-center whitespace-pre-line break-keep text-center text-sm theme-text-8">
              <span>
                {/* 주문 날짜 */}
                <span className="theme-text-10">
                  {formatDate(data.createdAt, {
                    dateSeparater: '.',
                  })}
                </span>
                주문하신 상품의
              </span>
              {/* 주문 번호 */}
              <span>
                주문번호는 <span className="theme-text-main">{data._id}</span> 입니다.
              </span>
            </p>
          </div>
          {/* 상품 정보 */}
          <div className="mx-4 mt-10 border-t border-b theme-bg-1 theme-border-4">
            {/* 상품 정보 */}
            <div className="flex p-2">
              {/* 이미지 */}
              <img
                src={fileBucketUrl(
                  data.orderDetail.shopItem.shopItemInfo.image.url,
                  'w400',
                )}
                alt="Product Image"
                className="mr-2 h-20 w-20 border border-gray-100 object-cover object-center"
              />
              {/* Text Info */}
              <div>
                {/* 상품명 */}
                <h4 className="text-sm font-bold">
                  {data.orderDetail.shopItemEmbed.name}
                </h4>
                {/* 도착 예졍 일 / 시각 */}
                <div className="mt-1 text-xs theme-text-main">
                  {formatDate(data.deliveryDetail.estimatedDeliveredAt, {
                    dateSeparater: '/',
                    contains: {
                      year: false,
                      day: true,
                      hours: true,
                      minutes: true,
                    },
                  })}
                  도착 예정
                </div>
                {/* 상품 개수 */}
                <div className="mt-5 text-xs theme-text-8">
                  {data.orderDetail.quantity}개
                </div>
              </div>
            </div>
            {/* 가격 정보 */}
            <div className="flex flex-col space-y-3 border-y border-gray-300 px-4 py-3 text-sm">
              {/* 상품 금액 */}
              <div className="flex items-center justify-between">
                <span className="theme-text-8">
                  상품 금액 ({data.orderDetail.quantity}개)
                </span>
                <span>
                  {formatPrice(data.orderDetail.shopItemEmbed.priceRetailShow)}원
                </span>
              </div>
              {/* 할인 금액 */}
              <div className="flex items-center justify-between">
                <span className="theme-text-8">할인 금액</span>
                <span className="text-[#00A3B0]">
                  {formatPrice(
                    data.orderDetail.shopItemEmbed.priceRetail -
                      data.orderDetail.shopItemEmbed.priceRetailShow,
                  )}
                  원
                </span>
              </div>
              {/* 포인트사용 */}
              <div className="flex items-center justify-between">
                <span className="theme-text-8">포인트사용</span>
                <span className="text-[#00A3B0]">
                  -{formatPrice(data.paymentDetail.paymentByPoint)}원
                </span>
              </div>
              {/* 배송비 */}
              <div className="flex items-center justify-between">
                <span className="theme-text-8">배송비</span>
                <span>무료배송</span>
              </div>
              {/* 결제금액 */}
              <div className="flex items-center justify-between">
                <span>결제금액</span>
                <span>
                  <span className="font-bold">
                    {formatPrice(
                      data.orderDetail.shopItemEmbed.priceRetail -
                        data.paymentDetail.paymentByPoint,
                    )}
                  </span>
                  원
                </span>
              </div>
            </div>
            {/* 사람 정보 */}
            <div className="px-4 py-3 text-sm">
              {/* 보내는 사람 */}
              <div>
                <div className="theme-text-8">보내는 사람</div>
                <p className="mt-1 font-bold">{data.bugoDetail.senderPhrase}</p>
              </div>
              {/* 조의 문구 */}
              <div className="mt-3">
                <div className="theme-text-8">조의문구</div>
                <p className="mt-1 font-bold">{data.bugoDetail.condolencePhrase}</p>
              </div>
            </div>
          </div>
          <div className="px-4 pt-8">
            <Link to={`/flower-store/?bugoId=${bugoId}`}>
              <Button className="button-rectangle theme-text-3 theme-bg-main">
                돌아가기
              </Button>
            </Link>
          </div>
        </>
      )}
    </div>
  );
};

export default OrderPaymentResultPage;
