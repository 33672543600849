import {
  shopOrderForStoreDataAtomFamily,
  shopOrderForStoreStatusAtomFamily,
  shopOrderListForAgencyAdminDataAtom,
  shopOrderListForAgencyAdminStatusAtom,
  shopOrderListForSellerByShopDataAtomFamily,
  shopOrderListForSellerByShopStatusAtomFamily,
  shopOrderWaitingListForSellerByShopDataAtomFamily,
  shopOrderWaitingListForSellerByShopStatusAtomFamily,
} from '@shared/api/shopOrder/shopOrder.atom';
import { StoreRole } from '@shared/api/shopOrder/shopOrder.interface';
import { fetcher, queryString, useRecoilQuery } from '@shared/hooks/recoil-query';
import { useAuth } from '@shared/state/hooks/useAuth';
import { LowerRole, PaymentState } from '@shared/types';
import _ from 'lodash';

import {
  shopOrderListForStoreDataAtom,
  shopOrderListForStoreStatusAtom,
} from './shopOrder.atom';

const shopOrderForStorePopulate = {
  populate: [
    {
      path: 'shop',
    },
    {
      path: 'orderDetail.shopItem',
      populate: [
        {
          path: 'shopItemInfo',
          populate: {
            path: 'image',
          },
        },
        {
          path: 'image',
        },
      ],
    },
    {
      path: 'deliveryDetail.deliveredImage',
    },
  ],
};

// admin, bugo-agency-admin, bugo-agency, store-customer, public(default)

const vaildRolesShopOrderForStore = [
  LowerRole.Admin,
  LowerRole.StoreCustomer,
  LowerRole.StoreGuest,
];

export const shopOrderListForStoreUrl = (storeRole: StoreRole) =>
  `/${storeRole}/shop-order/?${queryString(shopOrderForStorePopulate)}`;

export const useShopOrderListForStoreHook = (storeRole: StoreRole) => {
  const disabled = !_.includes(vaildRolesShopOrderForStore, storeRole);

  useRecoilQuery(
    shopOrderListForStoreStatusAtom,
    shopOrderListForStoreDataAtom,
    shopOrderListForStoreUrl(storeRole),
    fetcher,
    disabled,
  );
};

export const shopOrderForStoreUrl = (shopOrderId: string, storeRole: StoreRole) => {
  return `/${storeRole}/shop-order/${shopOrderId}?${queryString(
    shopOrderForStorePopulate,
  )}`;
};

export const useShopOrderForStoreHook = (
  shopOrderId: string,
  storeRole: StoreRole,
  disableState: boolean,
) => {
  const disabled = !(_.includes(vaildRolesShopOrderForStore, storeRole) || disableState);

  useRecoilQuery(
    shopOrderForStoreStatusAtomFamily(shopOrderId),
    shopOrderForStoreDataAtomFamily(shopOrderId),
    shopOrderForStoreUrl(shopOrderId, storeRole),
    fetcher,
    disabled,
  );
};

//*상조관리자가 볼 화환내역 페이지용
export const shopOrderListByBugoBrandUrl = (bugoRole: LowerRole) => {
  return `/${bugoRole}/shop-order/?${queryString({
    filter: {
      'paymentDetail.status': { $in: [PaymentState.PAID, PaymentState.CONFIRMED] },
    },
    populate: [
      {
        path: 'fevent',
        populate: {
          path: 'user',
        },
      },
    ],
    options: {
      sort: { createdAt: -1 },
    },
  })}`;
};

//*상조관리자가 볼 화환내역 페이지용
export const useShopOrderListByBugoBrandHook = () => {
  const { bugoRole } = useAuth();

  useRecoilQuery(
    shopOrderListForAgencyAdminStatusAtom,
    shopOrderListForAgencyAdminDataAtom,
    shopOrderListByBugoBrandUrl(bugoRole()),
    fetcher,
  );
};

//* seller용 shopOrder
export const shopOrderListForSellerByShopUrl = function (
  shopId: string,
  role: LowerRole,
) {
  return `${role}/shop-order/?${queryString({
    populate: [
      {
        path: 'user',
      },
      {
        path: 'bugo',
        populate: [
          {
            path: 'bugoBrand',
          },
          {
            path: 'fevent',
          },
        ],
      },
      {
        path: 'orderDetail.shopItem',
        populate: [
          {
            path: 'shopItemInfo',
            populate: {
              path: 'image',
            },
          },
          {
            path: 'image',
          },
        ],
      },
      {
        path: 'deliveryDetail',
        populate: {
          path: 'deliveredImage',
        },
      },
    ],
    filter: {
      shop: shopId,
      'paymentDetail.status': {
        $in: [
          PaymentState.CANCELED,
          PaymentState.CONFIRMED,
          PaymentState.PAID,
          PaymentState.CANCEL_REQUESTED,
        ],
      },
      'paymentDetail.isAccounted': { $ne: true },
    },
    options: {
      sort: {
        'paymentDetail.purchasedAt': -1,
      },
    },
  })}`;
};

export const useShopOrderListForSellerByShopHook = function (shopId: string) {
  const { sellerRole } = useAuth();

  useRecoilQuery(
    shopOrderListForSellerByShopStatusAtomFamily(shopId),
    shopOrderListForSellerByShopDataAtomFamily(shopId),
    shopOrderListForSellerByShopUrl(shopId, sellerRole()),
    fetcher,
  );
};

export const shopOrderWaitingListForSellerByShopUrl = function (
  shopId: string,
  role: LowerRole,
) {
  return `${role}/shop-order/?${queryString({
    populate: [
      {
        path: 'user',
      },
      {
        path: 'bugo',
        populate: [
          {
            path: 'bugoBrand',
          },
          {
            path: 'fevent',
          },
        ],
      },
      {
        path: 'orderDetail.shopItem',
        populate: [
          {
            path: 'shopItemInfo',
            populate: {
              path: 'image',
            },
          },
          {
            path: 'image',
          },
        ],
      },
    ],
    filter: {
      shop: shopId,
      'paymentDetail.status': {
        $in: [PaymentState.WAITING],
      },
      'paymentDetail.isAccounted': { $ne: true },
    },
    options: {
      sort: {
        createdAt: -1,
      },
    },
  })}`;
};
export const useShopOrderWaitingListForSellerByShopHook = function (shopId: string) {
  const { sellerRole } = useAuth();

  useRecoilQuery(
    shopOrderWaitingListForSellerByShopStatusAtomFamily(shopId),
    shopOrderWaitingListForSellerByShopDataAtomFamily(shopId),
    shopOrderWaitingListForSellerByShopUrl(shopId, sellerRole()),
    fetcher,
  );
};
