import { FC, useMemo } from 'react';

import PhraseInfoInputs from '@service/store/components/PhraseInfoInputs';
import { Button } from '@shared/components/Button';
import TelField from '@shared/components/TelField';
import { TextField } from '@shared/components/TextField';
import { Control, FieldValues, UseFormRegister, useWatch } from 'react-hook-form';

//* shopItem checkout form

interface Props {
  register: UseFormRegister<FieldValues>;
  control: Control<FieldValues, any>;
  creatingCallOrder: boolean;
}
export const CreateCallOrderForm: FC<Props> = function CreateCallOrderForm({
  register,
  control,
  creatingCallOrder,
}: Props) {
  //!만들어야할것
  // 1. 주문자정보
  // 2. 문구정보

  const telValue = useWatch({
    control,
    name: 'deliveryDetail.senderPhoneNumber',
    defaultValue: '',
  });

  const formRender = useMemo(() => {
    return (
      <div className="space-y-4 text-left">
        <div>
          <h4 className="font-bold">주문자 정보</h4>
          <div className="grid grid-cols-2 grid-rows-2 gap-x-2 gap-y-3 pt-3">
            <TextField
              className="w-full"
              placeholder="성함"
              {...register('deliveryDetail.senderName', { required: true })}
            />
            <TextField
              className="w-full"
              placeholder="비밀번호"
              {...register('senderPassword', { required: true })}
            />
            <div className="col-span-full">
              <TelField
                value={String(telValue)}
                register={register}
                name={'deliveryDetail.senderPhoneNumber'}
                className="w-full"
              />
            </div>
          </div>
        </div>

        <PhraseInfoInputs
          register={register}
          control={control}
          names={{
            phraseSender: 'bugoDetail.senderPhrase',
            phraseCondolence: 'bugoDetail.condolencePhrase',
          }}
        />
      </div>
    );
  }, [control, register, telValue]);

  return (
    <div>
      <div className="space-y-4 text-center">
        <p className="bugo-h1 text-18">정보 입력</p>
        {formRender}
        <div className="pt-4">
          <Button
            disabled={creatingCallOrder}
            className="button-rectangle text-base theme-text-3 theme-bg-main"
            type="submit"
          >
            주문생성
          </Button>
        </div>
      </div>
    </div>
  );
};
