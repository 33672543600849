import { fetcher, queryString, useRecoilQuery } from '@shared/hooks/recoil-query';
import { useAuth } from '@shared/state/hooks/useAuth';
import { LowerRole } from '@shared/types';

import {
  sellerShopDataAtomFamily,
  sellerShopListDataAtom,
  sellerShopListStatusAtom,
  sellerShopStatusAtomFamily,
} from './shop.atom';

export const sellerShopListHookUrl = (role: LowerRole) => {
  return `/${role}/shop`;
};

export const useSellerShopListHook = () => {
  const { isAdmin, isStoreSeller, sellerRole } = useAuth();
  useRecoilQuery(
    sellerShopListStatusAtom,
    sellerShopListDataAtom,
    sellerShopListHookUrl(sellerRole()),
    fetcher,
    !(isAdmin() || isStoreSeller()),
  );
};

export const sellerShopHookUrl = (shopId: string, role: LowerRole) => {
  return `/${role}/shop/${shopId}/?${queryString({
    populate: [
      {
        path: 'shopItemInfos',
        populate: [
          {
            path: 'image',
          },
          {
            path: 'detailImages',
          },
        ],
      },
      {
        path: 'shopItems',
        populate: [
          {
            path: 'image',
          },
          {
            path: 'detailImages',
          },
          {
            path: 'rebate',
          },
          {
            path: 'deliveredImageList',
          },
        ],
      },
    ],
  })}`;
};

export const useSellerShopHook = (shopId: string) => {
  const { isAdmin, isStoreSeller, sellerRole } = useAuth();
  useRecoilQuery(
    sellerShopStatusAtomFamily(shopId),
    sellerShopDataAtomFamily(shopId),
    sellerShopHookUrl(shopId, sellerRole()),
    fetcher,
    !(isAdmin() || isStoreSeller()),
  );
};
