import { CashAccount } from '@shared/api/cashAccount/cashAccount.interface';
import { FuneralHomeInfo } from '@shared/api/funeralHomeInfo/funeralHomeInfo.interface';
import { Image } from '@shared/api/media/media.interface';
import { Member } from '@shared/api/member/member.interface';
import { RoomInfo } from '@shared/api/roomInfo/roomInfo.interface';
import { ShopItem } from '@shared/api/shopItem/shopItem.interface';
import { ShopOrder } from '@shared/api/shopOrder/shopOrder.interface';
import { QueryDataState } from '@shared/interfaces';
import { FeventDate, Gender } from '@shared/types';

import { Bugo } from '../bugo/bugo.interface';
import { FuneralHomeInfoEmbed } from '../funeralHomeInfoRequest/funeralHomeInfoRequest.interface';
import { User } from '../user/user.interface';

export enum FeventStatus {
  Todo = 'Todo',
  Doing = 'Doing',
  Done = 'Done',
}

export type Fevent<
  User = string,
  FuneralHomeInfo = string,
  RoomInfo = string,
  Members = undefined,
  ShopOrders = undefined,
  MemberOrders = string[],
  DpImage = string,
  CashAccounts = undefined,
  CourtesyAsset = string,
  CourtesyContent = string,
> = {
  _id: string;
  user: User;
  bugoBrand: string;
  registerNumber?: string;
  funeralHomeInfo: FuneralHomeInfo;
  funeralHomeInfoEmbed?: FuneralHomeInfoEmbed;
  roomInfo?: RoomInfo;
  roomInfoEmbed?: {
    name: string;
  };
  meta: {
    deletedAt: Date;
    willBeDeletedAt: Date;
    isDeleted: boolean;
    courtesySend: boolean;
    willCourtesySend: Date;
  };
  members: Members;
  shopOrders: ShopOrders;
  cashAccounts?: CashAccounts;
  memberOrderList: MemberOrders;
  mournerText: string;
  deceasedInfo: {
    age: number;
    name: string;
    nameDetail?: string;
    cemetery: string;
    coffinIn: FeventDate;
    coffinOut: FeventDate;
    death: FeventDate;
    enterance: FeventDate;
    note?: string;
    bugoTerm: string;
    sex: Gender;
    dpImage?: DpImage;
  };
  courtesyAsset: CourtesyAsset;
  courtesyContent: CourtesyContent;
  status: FeventStatus;
  createdAt: Date;
  updatedAt: Date;
};

export type FeventOne = Fevent<
  string,
  FuneralHomeInfo,
  RoomInfo,
  undefined,
  ShopOrder<string, string, string, string, string, ShopItem<string, undefined, Image>>[],
  Member<string, Bugo, CashAccount>[],
  Image,
  CashAccount[]
>;

export type FeventList = Fevent<
  User,
  FuneralHomeInfo,
  RoomInfo,
  undefined,
  undefined,
  Member[]
>[];

export type FeventDoingList = Fevent<
  User,
  FuneralHomeInfo,
  RoomInfo,
  undefined,
  undefined,
  Member[]
>[];

export type FeventByBugoBrand = Fevent<
  User,
  FuneralHomeInfo,
  string,
  undefined,
  ShopOrder[],
  Member<string, Bugo>[]
>;

export type FeventByShop = Fevent<
  string,
  FuneralHomeInfo,
  RoomInfo,
  undefined,
  undefined,
  Member<string, Bugo>[]
>;

export type FeventState = QueryDataState<FeventOne>;

export type FeventListState = QueryDataState<FeventList>;

export type FeventListByBugoBrandState = QueryDataState<FeventByBugoBrand[]>;

export type FeventCountByBugoBrandState = QueryDataState<number>;

export type FeventDoingListState = QueryDataState<FeventDoingList>;

export type FeventCreate = Omit<
  Fevent,
  '_id' | 'createdAt' | 'updatedAt' | 'meta' | 'courtesyAsset' | 'courtesyContent'
>;

export type FeventUpdate<
  User = string,
  FuneralHomeInfo = string,
  RoomInfo = string,
  Members = undefined,
  ShopOrders = undefined,
  MemberOrders = string[],
  DpImage = string,
  CourtesyAsset = string,
  CourtesyContent = string,
> = {
  _id: string;
  registerNumber?: string;
  user?: User;
  funeralHomeInfo?: FuneralHomeInfo;
  funeralHomeInfoEmbed?: FuneralHomeInfoEmbed;
  roomInfo?: RoomInfo;
  roomInfoEmbed?: {
    name: string;
  };
  members?: Members;
  shopOrders?: ShopOrders;
  memberOrderList?: MemberOrders;
  mournerText?: string;
  deceasedInfo?: {
    age?: number;
    name?: string;
    nameDetail?: string;
    cemetery?: string;
    coffinIn?: FeventDate;
    coffinOut?: FeventDate;
    death?: FeventDate;
    enterance?: FeventDate;
    note?: string;
    bugoTerm?: string;
    sex?: Gender;
    dpImage?: DpImage;
  };
  courtesyAsset?: CourtesyAsset;
  courtesyContent?: CourtesyContent;
  status?: FeventStatus;
};
