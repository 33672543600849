//* 상주님이 받는 부고문자 페이지 (개인정보 동의, 계좌 등록이 가능함)

import { FC, useEffect, useMemo } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { bugoByMemberIdStateSelectorFamily } from '@shared/api/bugo/bugo.selector';
import { FeventStatus } from '@shared/api/fevent/fevent.interface';
import { Loading } from '@shared/components/Loading';
import ErrorNotFound from '@shared/containers/ErrorNotFound/ErrorNotFound';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { useParams } from 'react-router-dom';

import MemberObituary from '../containers/MemberObituary/MemberObituary';
import { useTitleHook } from '../hooks/useTitleHook';

const MemberObituaryPage: FC = function MemberObituaryPage() {
  useTitleHook('부고 공유하기(상주님 전용)', ' ', undefined, true, undefined, false);
  const { memberId } = useParams();
  const memberId_ = memberId ?? 'undefined';

  const bugoByMemberIdState = useRecoilValue(
    bugoByMemberIdStateSelectorFamily(memberId_),
  );
  const feventDoneModalOpenId = 'memberObituary-endFeventAlertModal';
  const setFeventDoneOpen = useSetRecoilState(
    simpleOpenAtomFamily(feventDoneModalOpenId),
  );

  useEffect(() => {
    if (bugoByMemberIdState.status === 'success' && bugoByMemberIdState.data) {
      if (bugoByMemberIdState.data.fevent.status === FeventStatus.Done) {
        setFeventDoneOpen(true);
      }
    }
  }, [bugoByMemberIdState.data, bugoByMemberIdState.status, setFeventDoneOpen]);

  const obituary = useMemo(() => {
    if (bugoByMemberIdState.status === 'success' && bugoByMemberIdState.data) {
      if (bugoByMemberIdState.data.fevent?.meta?.isDeleted) {
        return <ErrorNotFound announceString="해당 부고 파기됨" />;
      } else {
        return (
          <div className="max-w-inherit">
            <MemberObituary bugo={bugoByMemberIdState.data} />
          </div>
        );
      }
    } else {
      return (
        <div className="center-box h-screen-15">
          <Loading />
        </div>
      );
    }
  }, [bugoByMemberIdState]);

  return <div className="max-w-inherit">{obituary}</div>;
};

export default MemberObituaryPage;
